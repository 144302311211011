import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_USER = 'FETCH_USER'
const FETCH_USER_PENDING = 'FETCH_USER_PENDING'
const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED'
const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED'
// ACTION TYPES
const FETCH_USER_STATUS = 'FETCH_USER_STATUS'
const FETCH_USER_STATUS_PENDING = 'FETCH_USER_STATUS_PENDING'
const FETCH_USER_STATUS_REJECTED = 'FETCH_USER_STATUS_REJECTED'
const FETCH_USER_STATUS_FULFILLED = 'FETCH_USER_STATUS_FULFILLED'

const FETCH_USER_EXEMPTION = 'FETCH_USER_EXEMPTION'
const FETCH_USER_EXEMPTION_PENDING = 'FETCH_USER_EXEMPTION_PENDING'
const FETCH_USER_EXEMPTION_REJECTED = 'FETCH_USER_EXEMPTION_REJECTED'
const FETCH_USER_EXEMPTION_FULFILLED = 'FETCH_USER_EXEMPTION_FULFILLED'

// ACTION TYPES
const UPDATE_USER = 'UPDATE_USER'
const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED'
const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED'

// ACTION TYPES
const POST_USER = 'POST_USER'
const POST_USER_PENDING = 'POST_USER_PENDING'
const POST_USER_REJECTED = 'POST_USER_REJECTED'
const POST_USER_FULFILLED = 'POST_USER_FULFILLED'

// ACTION TYPES
const ENABLE_USER = 'ENABLE_USER'
const ENABLE_USER_PENDING = 'ENABLE_USER_PENDING'
const ENABLE_USER_REJECTED = 'ENABLE_USER_REJECTED'
const ENABLE_USER_FULFILLED = 'ENABLE_USER_FULFILLED'

// ACTION TYPES
const DISABLE_USER = 'DISABLE_USER'
const DISABLE_USER_PENDING = 'DISABLE_USER_PENDING'
const DISABLE_USER_REJECTED = 'DISABLE_USER_REJECTED'
const DISABLE_USER_FULFILLED = 'DISABLE_USER_FULFILLED'

const SAVE_MESSAGE_ADMIN = 'SAVE_MESSAGE_ADMIN'
const SAVE_MESSAGE_ADMIN_PENDING = 'SAVE_MESSAGE_ADMIN_PENDING'
const SAVE_MESSAGE_ADMIN_REJECTED = 'SAVE_MESSAGE_ADMIN_REJECTED'
const SAVE_MESSAGE_ADMIN_FULFILLED = 'SAVE_MESSAGE_ADMIN_FULFILLED'

const SAVE_MESSAGE_IGA = 'SAVE_MESSAGE_IGA'
const SAVE_MESSAGE_IGA_PENDING = 'SAVE_MESSAGE_IGA_PENDING'
const SAVE_MESSAGE_IGA_REJECTED = 'SAVE_MESSAGE_IGA_REJECTED'
const SAVE_MESSAGE_IGA_FULFILLED = 'SAVE_MESSAGE_IGA_FULFILLED'

// Action creators
export const fetchUser = (payload) => ({ type: FETCH_USER, payload })
export const fetchUsersPending = () => ({ type: FETCH_USER_PENDING })
export const fetchUsersRejected = (payload) => ({
  type: FETCH_USER_REJECTED,
  payload,
})
export const fetchUsersFulfilled = (payload) => ({
  type: FETCH_USER_FULFILLED,
  payload,
})

// Action creators
export const FetchUserstatus = () => ({ type: FETCH_USER_STATUS })
export const FetchUserstatusPending = () => ({
  type: FETCH_USER_STATUS_PENDING,
})
export const FetchUserstatusRejected = (payload) => ({
  type: FETCH_USER_STATUS_REJECTED,
  payload,
})
export const FetchUserstatusFulfilled = (payload) => ({
  type: FETCH_USER_STATUS_FULFILLED,
  payload,
})

// Action creators
export const FetchUserexemption = () => ({ type: FETCH_USER_EXEMPTION })
export const FetchUserexemptionPending = () => ({
  type: FETCH_USER_EXEMPTION_PENDING,
})
export const FetchUserexemptionRejected = (payload) => ({
  type: FETCH_USER_EXEMPTION_REJECTED,
  payload,
})
export const FetchUserexemptionFulfilled = (payload) => ({
  type: FETCH_USER_EXEMPTION_FULFILLED,
  payload,
})

// Action creators
export const updateUser = (user) => ({ type: UPDATE_USER, user })
export const updateUsersPending = () => ({ type: UPDATE_USER_PENDING })
export const updateUsersRejected = (payload) => ({
  type: UPDATE_USER_REJECTED,
  payload,
})
export const updateUsersFulfilled = (payload) => ({
  type: UPDATE_USER_FULFILLED,
  payload,
})

// Action creators

export const PostUser = (payload) => ({
  type: POST_USER,
  payload,
})
export const PostUsersPending = () => ({ type: POST_USER_PENDING })
export const PostUsersRejected = (payload) => ({
  type: POST_USER_REJECTED,
  payload,
})
export const PostUsersFulfilled = (payload) => ({
  type: POST_USER_FULFILLED,
  payload,
})

// Action creators
export const EnableUser = (payload) => ({ type: ENABLE_USER, payload })
export const EnableUsersPending = () => ({ type: ENABLE_USER_PENDING })
export const EnableUsersRejected = (payload) => ({
  type: ENABLE_USER_REJECTED,
  payload,
})
export const EnableUsersFulfilled = (payload) => ({
  type: ENABLE_USER_FULFILLED,
  payload,
})

// Action creators
export const DisableUser = (payload) => ({ type: DISABLE_USER, payload })
export const DisableUsersPending = () => ({ type: DISABLE_USER_PENDING })
export const DisableUsersRejected = (payload) => ({
  type: DISABLE_USER_REJECTED,
  payload,
})
export const DisableUsersFulfilled = (payload) => ({
  type: DISABLE_USER_FULFILLED,
  payload,
})

export const saveMessageAdmin = (payload) => ({
  type: SAVE_MESSAGE_ADMIN,
  payload,
})

export const saveMessageAdminPending = () => ({
  type: SAVE_MESSAGE_ADMIN_PENDING,
})
export const saveMessageAdminRejected = (payload) => ({
  type: SAVE_MESSAGE_ADMIN_REJECTED,
  payload,
})
export const saveMessageAdminFulfilled = (payload) => ({
  type: SAVE_MESSAGE_ADMIN_FULFILLED,
  payload,
})

export const saveMessageIGA = (payload) => ({ type: SAVE_MESSAGE_IGA, payload })

export const saveMessageIGAPending = () => ({
  type: SAVE_MESSAGE_IGA_PENDING,
})
export const saveMessageIGARejected = (payload) => ({
  type: SAVE_MESSAGE_IGA_REJECTED,
  payload,
})
export const saveMessageIGAFulfilled = (payload) => ({
  type: SAVE_MESSAGE_IGA_FULFILLED,
  payload,
})

// requetes HTTP AJAX

const fetchUserEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_USER),
      mergeMap((action) =>
        ajax({
          url: action.payload
            ? `${API_GREENSANTE}/admin/users?company_id=${action.payload}`
            : `${API_GREENSANTE}/admin/users`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchUsersFulfilled(xhr.response.data.users)),
          catchError((error) => of(fetchUsersRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchUsersPending()))

const fetchUserstatusEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_USER_STATUS),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/userStatus`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => FetchUserstatusFulfilled(xhr.response.data.usersStatus)),
          catchError((error) => of(FetchUserstatusRejected(error))),
        ),
      ),
    )
    .pipe(startWith(FetchUserstatusPending()))

const fethcUserexemptionEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_USER_EXEMPTION),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/userexemption`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            FetchUserexemptionFulfilled(xhr.response.data.userExemption),
          ),
          catchError((error) => of(FetchUserexemptionRejected(error))),
        ),
      ),
    )
    .pipe(startWith(FetchUserexemptionPending()))

const enableUserEpic = (action$) =>
  action$
    .pipe(
      ofType(ENABLE_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/users/enable`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            user_id: action.payload,
          },
        }).pipe(
          map((xhr) => EnableUsersFulfilled(xhr.response.data.user)),
          catchError((error) => of(EnableUsersRejected(error))),
        ),
      ),
    )
    .pipe(startWith(EnableUsersPending()))

const disableUserEpic = (action$) =>
  action$
    .pipe(
      ofType(DISABLE_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/users/disable`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            user_id: action.payload,
          },
        }).pipe(
          map((xhr) => DisableUsersFulfilled(xhr.response.data.user)),
          catchError((error) => of(DisableUsersRejected(error))),
        ),
      ),
    )
    .pipe(startWith(DisableUsersPending()))

const updateUserEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/users`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            user_id: action.user.user_id,
            email: action.user.emailUser,
            phone: action.user.phoneUser,
            userstatus_id: action.user.userstatus_id,
            guarantee_pack_id: action.user.guarantee_pack_id,
            contracttype_id: action.user.contracttype_id,
            firstname: action.user.FirstName,
            lastname: action.user.lastName,
            contact_id: action.user.contact_id,
            address_id: action.user.address_id,
            ownerid: action.user.ownerid,
            teletransmissionnumber: action.user.teletransmissionnumber,
            teletransmissionstateid: action.user.teletransmissionstateid,
            amccode: action.user.amccode,
            userexemption_id: action.user.userexemption_id,

            contact_info: JSON.stringify({
              name: action.user.nameContact,
              email: action.user.emailcontact,
              phone: action.user.phoneContact,
              whatsapp: action.user.whatsapp,
              slack: action.user.slack,
            }),
            address_info: JSON.stringify({
              name: action.user.nameAdresse,
              road_1: action.user.adresse,
              road_2: action.user.compliment,
              postcode: action.user.codePostal,
              city: action.user.ville,
              country: action.user.pays,
              email: action.user.emailAdresse,
              phone: action.user.phoneAdresse,
            }),
          },
        }).pipe(
          map((xhr) => updateUsersFulfilled(xhr.response.message)),
          catchError((error) =>
            of(updateUsersRejected(error.response.message)),
          ),
        ),
      ),
    )
    .pipe(startWith(updateUsersPending()))

const PostUserEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/users/registration`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            email: action.payload.email,
            password: action.payload.password,
            phone: action.payload.phone,
            company_id: action.payload.company_id,
            userstatus_id: action.payload.userstatus_id,
            guarantee_id: action.payload.guarantee_pack_id,
            contracttype_id: action.payload.contracttype_id,
            pricing_type_id: action.payload.pricing_type_id,
            firstname: action.payload.firstname,
            lastname: action.payload.lastname,
            ownerid: action.payload.ownerid,
            ownersecunumber: action.payload.ownersecunumber,
            owneriban: action.payload.owneriban,
            ownervalidationurl: action.payload.ownervalidationurl,
            concentrator: action.payload.concentrator,
            teletransmissionnumber: action.payload.teletransmissionnumber,
            teletransmissionstateid: action.payload.teletransmissionstateid,
            amccode: action.payload.amccode,
            userexemption_id: action.payload.userexemption_id,
            birthday: action.payload.birthday,
            civility_id: action.payload.civility_id,
            mariatal_status_id: action.payload.mariatal_status_id,

            contact_info: JSON.stringify({
              name: action.payload.contact.name,
              email: action.payload.contact.email,
              phone: action.payload.contact.phone,
              phone2: action.payload.contact.phone2,
              whatsapp: action.payload.contact.whatsapp,
              slack: action.payload.contact.slack,
            }),
            address_info: JSON.stringify({
              name: action.payload.address.name,
              road_1: action.payload.address.road_1,
              road_2: action.payload.address.road_2,
              postcode: action.payload.address.postcode,
              city: action.payload.address.city,
              country: action.payload.address.country,
              email: action.payload.address.email,
              phone: action.payload.address.phone,
            }),
          },
        }).pipe(
          map((xhr) => PostUsersFulfilled(xhr.response.data.user)),
          catchError((error) => of(PostUsersRejected(error.response.message))),
        ),
      ),
    )
    .pipe(startWith(PostUsersPending()))

const saveMessageAdminEpic = (action$) =>
  action$
    .pipe(
      ofType(SAVE_MESSAGE_ADMIN),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/user/message`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            response_user_id: action.payload.response_user_id,
            company_id: action.payload.company_id,
            text: action.payload.text,
          },
        }).pipe(
          map((xhr) => saveMessageAdminFulfilled(xhr.response.message[0])),
          catchError((error) => of(saveMessageAdminRejected(error))),
        ),
      ),
    )
    .pipe(startWith(saveMessageAdminPending()))

const saveMessageIGAEpic = (action$) =>
  action$
    .pipe(
      ofType(SAVE_MESSAGE_IGA),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/sendgest`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            response_user_id: action.payload.response_user_id,
            text: action.payload.text,
          },
        }).pipe(
          map((xhr) => saveMessageIGAFulfilled(xhr.response.data.message)),
          catchError((error) => of(saveMessageIGARejected(error))),
        ),
      ),
    )
    .pipe(startWith(saveMessageIGAPending()))

export const UserEpic = combineEpics(
  fetchUserEpic,
  updateUserEpic,
  PostUserEpic,
  enableUserEpic,
  disableUserEpic,
  fetchUserstatusEpic,
  fethcUserexemptionEpic,
  saveMessageAdminEpic,
  saveMessageIGAEpic,
)

const initialState = {
  users: [],
  usersStatus: [],
  usersexemption: [],
  userUpdate: null,
  isFetching: false,
  error: null,
}

export const UserReducer = (state = initialState, action) => {
  let users
  switch (action.type) {
    case FETCH_USER_PENDING:
      return { ...state, isFetching: true, users: initialState.users }
    case FETCH_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        users: initialState.users,
        error: action.payload,
      }
    case FETCH_USER_FULFILLED:
      return { ...state, isFetching: false, users: action.payload }

    case UPDATE_USER_PENDING:
      return { ...state, isFetching: true, users: initialState.users }
    case UPDATE_USER_REJECTED:
      return {
        ...state,
        isFetching: false,

        error: action.payload,
      }
    case UPDATE_USER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        error: null,
        userUpdate: action.payload,
      }
    case POST_USER_PENDING:
      return { ...state, isFetching: true, users: initialState.users }

    case POST_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case POST_USER_FULFILLED:
      users = [...state.users]
      users.unshift(action.payload)
      return {
        ...state,
        isFetching: false,
        error: null,
        users,
      }

    case ENABLE_USER_PENDING:
      return { ...state, isFetching: true, users: initialState.users }

    case ENABLE_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case ENABLE_USER_FULFILLED:
      users = [...state.users]
      users.forEach((item, index) => {
        if (action.payload.user_id === item.user_id) {
          users[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        error: null,
        users,
      }

    case DISABLE_USER_PENDING:
      return { ...state, isFetching: true, users: initialState.users }

    case DISABLE_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case DISABLE_USER_FULFILLED:
      users = [...state.users]
      users.forEach((item, index) => {
        if (action.payload.user_id === item.user_id) {
          users[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        error: null,
        users,
      }

    case FETCH_USER_STATUS_PENDING:
      return {
        ...state,
        isFetching: true,
        usersStatus: initialState.usersStatus,
      }

    case FETCH_USER_STATUS_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case FETCH_USER_STATUS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        usersStatus: action.payload,
      }

    case FETCH_USER_EXEMPTION_PENDING:
      return {
        ...state,
        isFetching: true,
        usersexemption: initialState.usersexemption,
      }

    case FETCH_USER_EXEMPTION_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case FETCH_USER_EXEMPTION_FULFILLED:
      return {
        ...state,
        isFetching: false,
        usersexemption: action.payload,
      }

    case SAVE_MESSAGE_ADMIN_PENDING:
      return {
        ...state,
        isFetching: true,
      }

    case SAVE_MESSAGE_ADMIN_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case SAVE_MESSAGE_ADMIN_FULFILLED:
      users = [...state.users]
      users.forEach((user, index) => {
        if (action.payload.user_id === user.user_id) {
          user.messages.unshift(action.payload)
        }
      })
      return {
        ...state,
        isFetching: false,
        users: users,
      }

    case SAVE_MESSAGE_IGA_PENDING:
      return {
        ...state,
        isFetching: true,
      }

    case SAVE_MESSAGE_IGA_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case SAVE_MESSAGE_IGA_FULFILLED:
      users = [...state.users]
      users.forEach((user, index) => {
        if (action.payload.user_id === user.user_id) {
          user.messages.unshift(action.payload)
        }
      })
      return {
        ...state,
        isFetching: false,
        users: users,
      }

    default:
      return state
  }
}
