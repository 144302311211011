require('dotenv').config();


export const API_GREENSANTE = process.env.REACT_APP_API_DEV === 'YES' ? process.env.REACT_APP_API_LOCAL : process.env.REACT_APP_API_PROD;

// RISQUE ID
export const RISK_SANTE_ID = 1
export const RISK_PREVOYANCE_ID = 2

//COLLECTION TYPE ID
export const OPTION_ID = 3
export const STANDARD_ID = 1
export const SUR_MESURE_ID = 2