import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_PACK = 'FETCH_PACK'
const FETCH_PACK_PENDING = 'FETCH_PACK_PENDING'
const FETCH_PACK_REJECTED = 'FETCH_PACK_REJECTED'
const FETCH_PACK_FULFILLED = 'FETCH_PACK_FULFILLED'

const ENABLE_GUARANTEE_PACK = 'ENABLE_GUARANTEE_PACK'
const ENABLE_GUARANTEE_PACK_PENDING = 'ENABLE_GUARANTEE_PACK_PENDING'
const ENABLE_GUARANTEE_PACK_REJECTED = 'ENABLE_GUARANTEE_PACK_REJECTED'
const ENABLE_GUARANTEE_PACK_FULFILLED = 'ENABLE_GUARANTEE_PACK_FULFILLED'

const DISABLE_GUARANTEE_PACK = 'DISABLE_GUARANTEE_PACK'
const DISABLE_GUARANTEE_PACK_PENDING = 'DISABLE_GUARANTEE_PACK_PENDING'
const DISABLE_GUARANTEE_PACK_REJECTED = 'DISABLE_GUARANTEE_PACK_REJECTED'
const DISABLE_GUARANTEE_PACK_FULFILLED = 'DISABLE_GUARANTEE_PACK_FULFILLED'

const FETCH_GARANTIEACTIVITY = 'FETCH_GARANTIEACTIVITY'
const FETCH_GARANTIEACTIVITY_PENDING = 'FETCH_GARANTIEACTIVITY_PENDING'
const FETCH_GARANTIEACTIVITY_REJECTED = 'FETCH_GARANTIEACTIVITY_REJECTED'
const FETCH_GARANTIEACTIVITY_FULFILLED = 'FETCH_GARANTIEACTIVITY_FULFILLED'

const FETCH_GARANTIE = 'FETCH_GARANTIE'
const FETCH_GARANTIE_PENDING = 'FETCH_GARANTIE_PENDING'
const FETCH_GARANTIE_REJECTED = 'FETCH_GARANTIE_REJECTED'
const FETCH_GARANTIE_FULFILLED = 'FETCH_GARANTIE_FULFILLED'

const FETCH_GARANTIECATEGORY = 'FETCH_GARANTIECATEGORY'
const FETCH_GARANTIECATEGORY_PENDING = 'FETCH_GARANTIECATEGORY_PENDING'
const FETCH_GARANTIECATEGORY_REJECTED = 'FETCH_GARANTIECATEGORY_REJECTED'
const FETCH_GARANTIECATEGORY_FULFILLED = 'FETCH_GARANTIECATEGORY_FULFILLED'

const FETCH_GARANTIECINFO = 'FETCH_GARANTIECINFO'
const FETCH_GARANTIECINFO_PENDING = 'FETCH_GARANTIECINFO_PENDING'
const FETCH_GARANTIECINFO_REJECTED = 'FETCH_GARANTIECINFO_REJECTED'
const FETCH_GARANTIECINFO_FULFILLED = 'FETCH_GARANTIECINFO_FULFILLED'

const FETCH_GARANTIEVALUE = 'FETCH_GARANTIEVALUE'
const FETCH_GARANTIEVALUE_PENDING = 'FETCH_GARANTIEVALUE_PENDING'
const FETCH_GARANTIEVALUE_REJECTED = 'FETCH_GARANTIEVALUE_REJECTED'
const FETCH_GARANTIEVALUE_FULFILLED = 'FETCH_GARANTIEVALUE_FULFILLED'

const POST_PACK = 'POST_PACK'
const POST_PACK_PENDING = 'POST_PACK_PENDING'
const POST_PACK_REJECTED = 'POST_PACK_REJECTED'
const POST_PACK_FULFILLED = 'POST_PACK_FULFILLED'

const POST_GARANTIEACTIVITY = 'POST_GARANTIEACTIVITY'
const POST_GARANTIEACTIVITY_PENDING = 'POST_GARANTIEACTIVITY_PENDING'
const POST_GARANTIEACTIVITY_REJECTED = 'POST_GARANTIEACTIVITY_REJECTED'
const POST_GARANTIEACTIVITY_FULFILLED = 'POST_GARANTIEACTIVITY_FULFILLED'

const UPDATE_GARANTIE_CATEGORIE = 'UPDATE_GARANTIE_CATEGORIE'
const UPDATE_GARANTIE_CATEGORIE_PENDING = 'UPDATE_GARANTIE_CATEGORIE_PENDING'
const UPDATE_GARANTIE_CATEGORIE_REJECTED = 'UPDATE_GARANTIE_CATEGORIE_REJECTED'
const UPDATE_GARANTIE_CATEGORIE_FULFILLED =
  'UPDATE_GARANTIE_CATEGORIE_FULFILLED'

const UPDATE_PACK = 'UPDATE_PACK'
const UPDATE_PACK_PENDING = 'UPDATE_PACK_PENDING'
const UPDATE_PACK_REJECTED = 'UPDATE_PACK_REJECTED'
const UPDATE_PACK_FULFILLED = 'UPDATE_PACK_FULFILLED'

const UPDATE_GUARANTEE = 'UPDATE_GUARANTEE'
const UPDATE_GUARANTEE_PENDING = 'UPDATE_GUARANTEE_PENDING'
const UPDATE_GUARANTEE_REJECTED = 'UPDATE_GUARANTEE_REJECTED'
const UPDATE_GUARANTEE_FULFILLED = 'UPDATE_GUARANTEE_FULFILLED'

const UPDATE_GARANTIEACTIVITY = 'UPDATE_GARANTIEACTIVITY'
const UPDATE_GARANTIEACTIVITY_PENDING = 'UPDATE_GARANTIEACTIVITY_PENDING'
const UPDATE_GARANTIEACTIVITY_REJECTED = 'UPDATE_GARANTIEACTIVITY_REJECTED'
const UPDATE_GARANTIEACTIVITY_FULFILLED = 'UPDATE_GARANTIEACTIVITY_FULFILLED'

const SAVE_GARANTIE_CATEGORIE = 'SAVE_GARANTIE_CATEGORIE'
const SAVE_GARANTIE_CATEGORIE_PENDING = 'SAVE_GARANTIE_CATEGORIE_PENDING'
const SAVE_GARANTIE_CATEGORIE_REJECTED = 'SAVE_GARANTIE_CATEGORIE_REJECTED'
const SAVE_GARANTIE_CATEGORIE_FULFILLED = 'SAVE_GARANTIE_CATEGORIE_FULFILLED'

const POST_GARANTIE = 'POST_GARANTIE'
const POST_GARANTIE_PENDING = 'POST_GARANTIE_PENDING'
const POST_GARANTIE_REJECTED = 'POST_GARANTIE_REJECTED'
const POST_GARANTIE_FULFILLED = 'POST_GARANTIE_FULFILLED'

const POST_GARANTIEVALUE = 'POST_GARANTIEVALUE'
const POST_GARANTIEVALUE_PENDING = 'POST_GARANTIEVALUE_PENDING'
const POST_GARANTIEVALUE_REJECTED = 'POST_GARANTIEVALUE_REJECTED'
const POST_GARANTIEVALUE_FULFILLED = 'POST_GARANTIEVALUE_FULFILLED'

const DELETE_PACK = 'DELETE_PACK'
const DELETE_PACK_PENDING = 'DELETE_PACK_PENDING'
const DELETE_PACK_REJECTED = 'DELETE_PACK_REJECTED'
const DELETE_PACK_FULFILLED = 'DELETE_PACK_FULFILLED'

// updates ID state
const ID_GARANTIEGATEGORIE = 'ID_GARANTIEGATEGORIE'
const ID_GATEGORIE = 'ID_GATEGORIE'
const ID_PACK = 'ID_PACK'

// Action creators
export const fetchPacks = () => ({ type: FETCH_PACK })
export const fetchPacksPending = () => ({ type: FETCH_PACK_PENDING })
export const fetchPacksRejected = (payload) => ({
  type: FETCH_PACK_REJECTED,
  payload,
})
export const fetchPacksFulfilled = (payload) => ({
  type: FETCH_PACK_FULFILLED,
  payload,
})

export const enableGuaranteePack = (payload) => ({
  type: ENABLE_GUARANTEE_PACK,
  payload,
})
export const enableGuaranteePackPending = () => ({
  type: ENABLE_GUARANTEE_PACK_PENDING,
})
export const enableGuaranteePackRejected = (payload) => ({
  type: ENABLE_GUARANTEE_PACK_REJECTED,
  payload,
})
export const enableGuaranteePackFulfilled = (payload) => ({
  type: ENABLE_GUARANTEE_PACK_FULFILLED,
  payload,
})

export const disableGuaranteePack = (payload) => ({
  type: DISABLE_GUARANTEE_PACK,
  payload,
})
export const disableGuaranteePackPending = () => ({
  type: DISABLE_GUARANTEE_PACK_PENDING,
})
export const disableGuaranteePackRejected = (payload) => ({
  type: DISABLE_GUARANTEE_PACK_REJECTED,
  payload,
})
export const disableGuaranteePackFulfilled = (payload) => ({
  type: DISABLE_GUARANTEE_PACK_FULFILLED,
  payload,
})

export const fetchGarantieInfo = (payload) => ({
  type: FETCH_GARANTIECINFO,
  payload,
})
export const fetchGarantieInfoPending = () => ({
  type: FETCH_GARANTIECINFO_PENDING,
})
export const fetchGarantieInfoRejected = (payload) => ({
  type: FETCH_GARANTIECINFO_REJECTED,
  payload,
})
export const fetchGarantieInfoFulfilled = (payload) => ({
  type: FETCH_GARANTIECINFO_FULFILLED,
  payload,
})

export const fetchGarantieValue = (payload) => ({
  type: FETCH_GARANTIEVALUE,
  payload,
})
export const fetchGarantieValuePending = () => ({
  type: FETCH_GARANTIEVALUE_PENDING,
})
export const fetchGarantieValueRejected = (payload) => ({
  type: FETCH_GARANTIEVALUE_REJECTED,
  payload,
})
export const fetchGarantieValueFulfilled = (payload) => ({
  type: FETCH_GARANTIEVALUE_FULFILLED,
  payload,
})

export const fetchGarantieActivity = () => ({ type: FETCH_GARANTIEACTIVITY })
export const fetchGarantieActivityPending = () => ({
  type: FETCH_GARANTIEACTIVITY_PENDING,
})
export const fetchGarantieActivityRejected = (payload) => ({
  type: FETCH_GARANTIEACTIVITY_REJECTED,
  payload,
})
export const fetchGarantieActivityFulfilled = (payload) => ({
  type: FETCH_GARANTIEACTIVITY_FULFILLED,
  payload,
})

export const fetchGarantie = (param) => ({ type: FETCH_GARANTIE, param })
export const fetchGarantiePending = () => ({ type: FETCH_GARANTIE_PENDING })
export const fetchGarantieRejected = (payload) => ({
  type: FETCH_GARANTIE_REJECTED,
  payload,
})
export const fetchGarantieFulfilled = (payload) => ({
  type: FETCH_GARANTIE_FULFILLED,
  payload,
})

export const fetchGarantieCategorie = (payload) => ({
  type: FETCH_GARANTIECATEGORY,
  payload,
})
export const fetchGarantieCategoriePending = () => ({
  type: FETCH_GARANTIECATEGORY_PENDING,
})
export const fetchGarantieCategorieRejected = (payload) => ({
  type: FETCH_GARANTIECATEGORY_REJECTED,
  payload,
})
export const fetchGarantieCategorieFulfilled = (payload) => ({
  type: FETCH_GARANTIECATEGORY_FULFILLED,
  payload,
})

export const PostPack = (pack) => ({ type: POST_PACK, pack })
export const PostPackPending = () => ({ type: POST_PACK_PENDING })
export const PostPackRejected = (payload) => ({
  type: POST_PACK_REJECTED,
  payload,
})
export const PostPackFulfilled = (payload) => ({
  type: POST_PACK_FULFILLED,
  payload,
})

export const PostGarantieActivity = (payload) => ({
  type: POST_GARANTIEACTIVITY,
  payload,
})
export const PostGarantieActivityPending = () => ({
  type: POST_GARANTIEACTIVITY_PENDING,
})
export const PostGarantieActivityRejected = (payload) => ({
  type: POST_GARANTIEACTIVITY_REJECTED,
  payload,
})
export const PostGarantieActivityFulfilled = (payload) => ({
  type: POST_GARANTIEACTIVITY_FULFILLED,
  payload,
})

export const UpdateGuaranteeCategorie = (payload) => ({
  type: UPDATE_GARANTIE_CATEGORIE,
  payload,
})
export const UpdateGuaranteeCategoriePending = () => ({
  type: UPDATE_GARANTIE_CATEGORIE_PENDING,
})
export const UpdateGuaranteeCategorieRejected = (payload) => ({
  type: UPDATE_GARANTIE_CATEGORIE_REJECTED,
  payload,
})
export const UpdateGuaranteeCategorieFulfilled = (payload) => ({
  type: UPDATE_GARANTIE_CATEGORIE_FULFILLED,
  payload,
})

export const SaveGarantieCategorie = (payload) => ({
  type: SAVE_GARANTIE_CATEGORIE,
  payload,
})
export const SaveGarantieCategoriePending = () => ({
  type: SAVE_GARANTIE_CATEGORIE_PENDING,
})
export const SaveGarantieCategorieRejected = (payload) => ({
  type: SAVE_GARANTIE_CATEGORIE_REJECTED,
  payload,
})
export const SaveGarantieCategorieFulfilled = (payload) => ({
  type: SAVE_GARANTIE_CATEGORIE_FULFILLED,
  payload,
})

export const PostGarantie = (payload) => ({
  type: POST_GARANTIE,
  payload,
})
export const PostGarantiePending = () => ({
  type: POST_GARANTIE_PENDING,
})
export const PostGarantieRejected = (payload) => ({
  type: POST_GARANTIE_REJECTED,
  payload,
})
export const PostGarantieFulfilled = (payload) => ({
  type: POST_GARANTIE_FULFILLED,
  payload,
})

export const PostGarantieValue = (payload) => ({
  type: POST_GARANTIEVALUE,
  payload,
})
export const PostGarantieValuePending = () => ({
  type: POST_GARANTIEVALUE_PENDING,
})
export const PostGarantieValueRejected = (payload) => ({
  type: POST_GARANTIEVALUE_REJECTED,
  payload,
})
export const PostGarantieValueFulfilled = (payload) => ({
  type: POST_GARANTIEVALUE_FULFILLED,
  payload,
})

export const UpdatePack = (pack) => ({ type: UPDATE_PACK, pack })
export const UpdatePackPending = () => ({ type: UPDATE_PACK_PENDING })
export const UpdatePackRejected = (payload) => ({
  type: UPDATE_PACK_REJECTED,
  payload,
})
export const UpdatePackFulfilled = (payload) => ({
  type: UPDATE_PACK_FULFILLED,
  payload,
})

export const UpdateGuarantee = (payload) => ({
  type: UPDATE_GUARANTEE,
  payload,
})
export const UpdateGuaranteePending = () => ({ type: UPDATE_GUARANTEE_PENDING })
export const UpdateGuaranteeRejected = (payload) => ({
  type: UPDATE_GUARANTEE_REJECTED,
  payload,
})
export const UpdateGuaranteeFulfilled = (payload) => ({
  type: UPDATE_GUARANTEE_FULFILLED,
  payload,
})

export const UpdateGarantieActivity = (pack) => ({
  type: UPDATE_GARANTIEACTIVITY,
  pack,
})
export const UpdateGarantieActivityPending = () => ({
  type: UPDATE_GARANTIEACTIVITY_PENDING,
})
export const UpdateGarantieActivityRejected = (payload) => ({
  type: UPDATE_GARANTIEACTIVITY_REJECTED,
  payload,
})
export const UpdateGarantieActivityFulfilled = (payload) => ({
  type: UPDATE_GARANTIEACTIVITY_FULFILLED,
  payload,
})

export const deletePack = (id) => ({ type: DELETE_PACK, id })
export const DeletePackPending = () => ({ type: DELETE_PACK_PENDING })
export const DeletePackRejected = (payload) => ({
  type: DELETE_PACK_REJECTED,
  payload,
})
export const DeletePackFulfilled = (payload) => ({
  type: DELETE_PACK_FULFILLED,
  payload,
})

// actions ids

export const passIdsToGarantieValue = (payload) => ({
  type: ID_GARANTIEGATEGORIE,
  payload,
})

export const passIdCategorieTogarantie = (payload) => ({
  type: ID_GATEGORIE,
  payload,
})

export const passIdPackToBrother = (payload) => ({
  type: ID_PACK,
  payload,
})

// requetes HTTP AJAX
const fetchPacksEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_pack`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchPacksFulfilled(xhr.response.data.guarantee_pack)),
          catchError((error) => of(fetchPacksRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchPacksPending()))

const enableGuaranteePackEpic = (action$) =>
  action$
    .pipe(
      ofType(ENABLE_GUARANTEE_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee/enable`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            guarantee_pack_id: action.payload,
          },
        }).pipe(
          map((xhr) =>
            enableGuaranteePackFulfilled(xhr.response.data.guarantee_pack[0]),
          ),
          catchError((error) => of(enableGuaranteePackRejected(error))),
        ),
      ),
    )
    .pipe(startWith(enableGuaranteePackPending()))

const disableGuaranteePackEpic = (action$) =>
  action$
    .pipe(
      ofType(DISABLE_GUARANTEE_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee/disable`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            guarantee_pack_id: action.payload,
          },
        }).pipe(
          map((xhr) =>
            disableGuaranteePackFulfilled(xhr.response.data.guarantee_pack[0]),
          ),
          catchError((error) => of(disableGuaranteePackRejected(error))),
        ),
      ),
    )
    .pipe(startWith(disableGuaranteePackPending()))

const fetchGarantieInfoEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_GARANTIECINFO),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_pack_info/${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchGarantieInfoFulfilled(xhr.response.data.activities),
          ),
          catchError((error) => of(fetchGarantieInfoRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchGarantieInfoPending()))

const fetchGarantieValueEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_GARANTIEVALUE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee/${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchGarantieValueFulfilled(xhr.response.data.values)),
          catchError((error) => of(fetchGarantieValueRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchGarantieValuePending()))

const fetchGarantieActivityEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_GARANTIEACTIVITY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_activity`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchGarantieActivityFulfilled(xhr.response.activities)),
          catchError((error) => of(fetchGarantieActivityRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchGarantieActivityPending()))

const fetchGarantieCategorieEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_GARANTIECATEGORY),
      mergeMap((action) =>
        ajax({
          url: action.payload
            ? `${API_GREENSANTE}/admin/guarantee_category?guarantee_activity_id=` +
            `${action.payload}`
            : `${API_GREENSANTE}/admin/guarantee_category`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchGarantieCategorieFulfilled(xhr.response.categories),
          ),
          catchError((error) => of(fetchGarantieCategorieRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchGarantieCategoriePending()))

const fetchGarantieEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_GARANTIE),
      mergeMap((action) =>
        ajax({
          url: action.param
            ? `${API_GREENSANTE}/admin/guarantee?guarantee_activity_id=` +
            `${action.param.guarantee_activity_id}` +
            `&guarantee_category_id=` +
            `${action.param.guarantee_category_id}`
            : `${API_GREENSANTE}/admin/guarantee`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchGarantieFulfilled(xhr.response.garanties)),
          catchError((error) => of(fetchGarantieRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchGarantiePending()))

const PostPackEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/packs`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.pack.name,
            description: action.pack.description,
            weight: action.pack.weight,
          },
        }).pipe(
          map((xhr) => PostPackFulfilled(xhr.response.pack[0])),
          catchError((error) => of(PostPackRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostPackPending()))

const SaveGarantieCategorieEpic = (action$) =>
  action$
    .pipe(
      ofType(SAVE_GARANTIE_CATEGORIE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_category`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
            guarantee_activity_id: action.payload.guarantee_activity_id,
            code: action.payload.code,
            description: action.payload.description,
          },
        }).pipe(
          map((xhr) =>
            SaveGarantieCategorieFulfilled(xhr.response.data.categorie[0]),
          ),
          catchError((error) =>
            of(SaveGarantieCategorieRejected(error.message)),
          ),
        ),
      ),
    )
    .pipe(startWith(SaveGarantieCategoriePending()))

const PostGarantieEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_GARANTIE),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/admin/guarantee?guarantee_activity_id=` +
            `${action.payload.guarantee_activity_id}` +
            `&guarantee_category_id=` +
            `${action.payload.categorie_id}`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
          },
        }).pipe(
          map((xhr) => PostGarantieFulfilled(xhr.response.garantie[0])),
          catchError((error) => of(PostGarantieRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostGarantiePending()))

const PostGarantieActivityEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_GARANTIEACTIVITY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_activity`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
          },
        }).pipe(
          map((xhr) =>
            PostGarantieActivityFulfilled(xhr.response.guaranteeActivitie[0]),
          ),
          catchError((error) => of(PostGarantieActivityRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostGarantieActivityPending()))

const UpdateGuaranteeCategorieEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_GARANTIE_CATEGORIE),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/admin/guarantee_category?guarantee_activity_id=` +
            `${action.payload.guarantee_activity_id}`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
          },
        }).pipe(
          map((xhr) =>
            UpdateGuaranteeCategorieFulfilled(xhr.response.data.categorie[0]),
          ),
          catchError((error) => of(UpdateGuaranteeCategorieRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateGuaranteeCategoriePending()))

const PostGarantieValueEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_GARANTIEVALUE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_value`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            guarantee_pack_id: action.payload.guarantee_pack_id,
            guarantee_id: action.payload.garantie_id,
            txtvalue: action.payload.txtvalue,
            description: action.payload.description,
            value: action.payload.value,
            exemple: action.payload.exemple,
          },
        }).pipe(
          map((xhr) => PostGarantieValueFulfilled(xhr.response.categorie[0])),
          catchError((error) => of(PostGarantieValueRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostGarantieValuePending()))

const UpdatePackEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/packs/${action.pack.guarantee_pack_id}`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.pack.name,
            description: action.pack.description,
            weight: action.pack.weight,
          },
        }).pipe(
          map((xhr) => UpdatePackFulfilled(xhr.response)),
          catchError((error) => of(UpdatePackRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdatePackPending()))

const UpdateGuaranteeEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_GUARANTEE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee/${action.payload.guarantee_id}`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
          },
        }).pipe(
          map((xhr) => UpdateGuaranteeFulfilled(xhr.response)),
          catchError((error) => of(UpdateGuaranteeRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateGuaranteePending()))

const UpdateGarantieActivityEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_GARANTIEACTIVITY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/guarantee_activity/${action.payload.guarantee_activity_id}`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
          },
        }).pipe(
          map((xhr) =>
            UpdateGarantieActivityFulfilled(xhr.response.guaranteeActivitie[0]),
          ),
          catchError((error) => of(UpdateGarantieActivityRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateGarantieActivityPending()))

const DeletePackEpic = (action$) =>
  action$
    .pipe(
      ofType(DELETE_PACK),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/packs/${action.id}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => DeletePackFulfilled(xhr.response)),
          catchError((error) => of(DeletePackRejected(error))),
        ),
      ),
    )
    .pipe(startWith(DeletePackPending()))

export const PackEpic = combineEpics(
  fetchPacksEpic,
  fetchGarantieActivityEpic,
  fetchGarantieCategorieEpic,
  fetchGarantieEpic,
  fetchGarantieInfoEpic,
  fetchGarantieValueEpic,
  PostPackEpic,
  PostGarantieEpic,
  PostGarantieActivityEpic,
  UpdateGuaranteeCategorieEpic,
  UpdatePackEpic,
  UpdateGuaranteeEpic,
  PostGarantieValueEpic,
  UpdateGarantieActivityEpic,
  DeletePackEpic,
  enableGuaranteePackEpic,
  disableGuaranteePackEpic,
  SaveGarantieCategorieEpic,
)

const initialState = {
  packs: [],
  garantieActivis: [],
  garantieCategories: [],
  garanties: [],
  garantiesInfo: [],
  garantiesValues: [],
  pack_id: '',
  activity_id: '',
  garantie_id: '',
  categorie_id: '',
  isFetching: false,
  error: null,
}

export const garantieReducer = (state = initialState, action) => {
  let packs
  let garantieCategories
  // let garanties

  switch (action.type) {
    case FETCH_PACK_PENDING:
      return { ...state, isFetching: true, packs: initialState.packs }
    case FETCH_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        packs: initialState.packs,
        error: action.payload,
      }
    case FETCH_PACK_FULFILLED:
      return { ...state, isFetching: false, packs: action.payload }

    case ENABLE_GUARANTEE_PACK_PENDING:
      return { ...state, isFetching: true, packs: initialState.packs }
    case ENABLE_GUARANTEE_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        packs: initialState.packs,
        error: action.payload,
      }
    case ENABLE_GUARANTEE_PACK_FULFILLED:
      packs = [...state.packs]
      packs.forEach((item, index) => {
        if (action.payload.guarantee_pack_id === item.guarantee_pack_id) {
          console.log(action.payload.guarantee_pack_id)
          packs[index] = action.payload
        }
      })
      return { ...state, isFetching: false, packs: packs }

    case DISABLE_GUARANTEE_PACK_PENDING:
      return { ...state, isFetching: true, packs: initialState.packs }
    case DISABLE_GUARANTEE_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        packs: initialState.packs,
        error: action.payload,
      }
    case DISABLE_GUARANTEE_PACK_FULFILLED:
      packs = [...state.packs]
      packs.forEach((item, index) => {
        if (action.payload.guarantee_pack_id === item.guarantee_pack_id) {
          packs[index] = action.payload
        }
      })
      return { ...state, isFetching: false, packs }

    case FETCH_GARANTIECINFO:
      return {
        ...state,
        pack_id: action.payload,
      }

    case FETCH_GARANTIECINFO_PENDING:
      return {
        ...state,
        isFetching: true,
        garantiesInfo: initialState.garantiesInfo,
      }
    case FETCH_GARANTIECINFO_REJECTED:
      return {
        ...state,
        isFetching: false,
        garantiesInfo: initialState.garantiesInfo,
        error: action.payload,
      }
    case FETCH_GARANTIECINFO_FULFILLED:
      return { ...state, isFetching: false, garantiesInfo: action.payload }

    case FETCH_GARANTIEVALUE_PENDING:
      return {
        ...state,
        isFetching: true,
        garantiesValues: initialState.garantiesValues,
      }
    case FETCH_GARANTIEVALUE_REJECTED:
      return {
        ...state,
        isFetching: false,
        garantiesValues: initialState.garantiesValues,
        error: action.payload,
      }
    case FETCH_GARANTIEVALUE_FULFILLED:
      return { ...state, isFetching: false, garantiesValues: action.payload }

    case FETCH_GARANTIEACTIVITY_PENDING:
      return {
        ...state,
        isFetching: true,
        garantieActivis: initialState.garantieActivis,
      }
    case FETCH_GARANTIEACTIVITY_REJECTED:
      return {
        ...state,
        isFetching: false,
        garantieActivis: initialState.garantieActivis,
        error: action.payload,
      }
    case FETCH_GARANTIEACTIVITY_FULFILLED:
      return { ...state, isFetching: false, garantieActivis: action.payload }

    case FETCH_GARANTIECATEGORY:
      return { ...state, activity_id: action.payload }

    case FETCH_GARANTIECATEGORY_PENDING:
      return {
        ...state,
        isFetching: true,
        garantieCategories: initialState.garantieCategories,
      }
    case FETCH_GARANTIECATEGORY_REJECTED:
      return {
        ...state,
        isFetching: false,
        garantieCategories: initialState.garantieCategories,
        error: action.payload,
      }
    case FETCH_GARANTIECATEGORY_FULFILLED:
      return { ...state, isFetching: false, garantieCategories: action.payload }

    case FETCH_GARANTIE:
      return {
        ...state,
        categorie_id: action.param ? action.param.guarantee_category_id : '',
      }

    case FETCH_GARANTIE_PENDING:
      return { ...state, isFetching: true, garanties: initialState.garanties }
    case FETCH_GARANTIE_REJECTED:
      return {
        ...state,
        isFetching: false,
        garanties: initialState.garanties,
        error: action.payload,
      }
    case FETCH_GARANTIE_FULFILLED:
      return { ...state, isFetching: false, garanties: action.payload }

    case POST_PACK_PENDING:
      return { ...state, isFetching: true, error: null }
    case POST_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case POST_PACK_FULFILLED:
      packs = [...state.packs]
      packs.push(action.payload)
      return { ...state, isFetching: false, packs, error: 'no error pack' }

    case POST_GARANTIE_PENDING:
      return { ...state, isFetching: true, error: null }
    case POST_GARANTIE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case POST_GARANTIE_FULFILLED:
      let garanties = [...state.garanties]
      garanties.push(action.payload)
      return {
        ...state,
        isFetching: false,
        garanties,
        error: 'no error garantie',
      }

    case POST_GARANTIEACTIVITY_PENDING:
      return { ...state, isFetching: true, error: null }
    case POST_GARANTIEACTIVITY_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case POST_GARANTIEACTIVITY_FULFILLED:
      let garantieActivis = [...state.garantieActivis]
      garantieActivis.push(action.payload)
      return {
        ...state,
        isFetching: false,
        garantieActivis,
        error: 'no error activity',
      }

    case UPDATE_GARANTIE_CATEGORIE_PENDING:
      return { ...state, isFetching: true, error: null }
    case UPDATE_GARANTIE_CATEGORIE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case UPDATE_GARANTIE_CATEGORIE_FULFILLED:
      garantieCategories = [...state.garantieCategories]
      garantieCategories.forEach((item, index) => {
        if (
          action.payload.guarantee_category_id === item.guarantee_category_id
        ) {
          garantieCategories[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        garantieCategories,
        error: 'no error categorie',
      }

    case UPDATE_GUARANTEE_PENDING:
      return { ...state, isFetching: true, error: null }
    case UPDATE_GUARANTEE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case UPDATE_GUARANTEE_FULFILLED:
      garanties = [...state.garanties]
      garanties.forEach((item, index) => {
        if (action.payload.guarantee_id === item.guarantee_id) {
          garanties[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        garanties,
        error: 'no error categorie',
      }

    case SAVE_GARANTIE_CATEGORIE_PENDING:
      return { ...state, isFetching: true, error: null }
    case SAVE_GARANTIE_CATEGORIE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case SAVE_GARANTIE_CATEGORIE_FULFILLED:
      garantieCategories = [...state.garantieCategories]
      garantieCategories.push(action.payload)
      return {
        ...state,
        isFetching: false,
        garantieCategories,
        error: 'no error categorie',
      }

    case UPDATE_PACK_PENDING:
      return { ...state, isFetching: true, error: null }
    case UPDATE_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case UPDATE_PACK_FULFILLED:
      return { ...state, isFetching: false }

    case POST_GARANTIEVALUE_PENDING:
      return { ...state, isFetching: true, error: null }
    case POST_GARANTIEVALUE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case POST_GARANTIEVALUE_FULFILLED:
      return { ...state, isFetching: false, error: 'no error garantie value' }

    case UPDATE_GARANTIEACTIVITY_PENDING:
      return { ...state, isFetching: true, error: null }
    case UPDATE_GARANTIEACTIVITY_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case UPDATE_GARANTIEACTIVITY_FULFILLED:
      return { ...state, isFetching: false }

    case DELETE_PACK_PENDING:
      return { ...state, isFetching: true, error: null }
    case DELETE_PACK_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case DELETE_PACK_FULFILLED:
      return { ...state, isFetching: false }

    case ID_GARANTIEGATEGORIE:
      return {
        ...state,
        garantie_id: action.payload.guarantee_id,
      }

    case ID_GATEGORIE:
      return {
        ...state,
        categorie_id: action.payload.categorie_id,
      }

    case ID_PACK:
      return {
        ...state,
        pack_id: action.payload,
      }

    default:
      return state
  }
}
