import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_COMPAGNY = 'FETCH_COMPAGNY'
const FETCH_COMPAGNY_PENDING = 'FETCH_COMPAGNY_PENDING'
const FETCH_COMPAGNY_REJECTED = 'FETCH_COMPAGNY_REJECTED'
const FETCH_COMPAGNY_FULFILLED = 'FETCH_COMPAGNY_FULFILLED'

const FETCH_COMPAGNYSTATUS = 'FETCH_COMPAGNYSTATUS'
const FETCH_COMPAGNYSTATUS_PENDING = 'FETCH_COMPAGNYSTATUS_PENDING'
const FETCH_COMPAGNYSTATUS_REJECTED = 'FETCH_COMPAGNYSTATUS_REJECTED'
const FETCH_COMPAGNYSTATUS_FULFILLED = 'FETCH_COMPAGNYSTATUS_FULFILLED'

const FETCH_COMPAGNYDETAILLE = 'FETCH_COMPAGNYDETAILLE'
const FETCH_COMPAGNYDETAILLE_PENDING = 'FETCH_COMPAGNYDETAILLE_PENDING'
const FETCH_COMPAGNYDETAILLE_REJECTED = 'FETCH_COMPAGNYDETAILLE_REJECTED'
const FETCH_COMPAGNYDETAILLE_FULFILLED = 'FETCH_COMPAGNYDETAILLE_FULFILLED'

const UPDATE_COMPANYSTATUS = 'UPDATE_COMPANYSTATUS'
const UPDATE_COMPANYSTATUS_PENDING = 'UPDATE_COMPANYSTATUS_PENDING'
const UPDATE_COMPANYSTATUS_REJECTED = 'UPDATE_COMPANYSTATUS_REJECTED'
const UPDATE_COMPANYSTATUS_FULFILLED = 'UPDATE_COMPANYSTATUS_FULFILLED'

const UPDATE_COMPANY = 'UPDATE_COMPANY'
const UPDATE_COMPANY_PENDING = 'UPDATE_COMPANY_PENDING'
const UPDATE_COMPANY_REJECTED = 'UPDATE_COMPANY_REJECTED'
const UPDATE_COMPANY_FULFILLED = 'UPDATE_COMPANY_FULFILLED'

const PUT_COMPANY_enable = 'PUT_COMPANY_enable'
const PUT_COMPANY_enable_PENDING = 'PUT_COMPANY_enable_PENDING'
const PUT_COMPANY_enable_REJECTED = 'PUT_COMPANY_enable_REJECTED'
const PUT_COMPANY_enable_FULFILLED = 'PUT_COMPANY_enable_FULFILLED'

const POST_COMPANY = 'POST_COMPANY'
const POST_COMPANY_PENDING = 'POST_COMPANY_PENDING'
const POST_COMPANY_REJECTED = 'POST_COMPANY_REJECTED'
const POST_COMPANY_FULFILLED = 'POST_COMPANY_FULFILLED'

const DELETE_COMPANY = 'DELETE_COMPANY'
const DELETE_COMPANY_PENDING = 'DELETE_COMPANY_PENDING'
const DELETE_COMPANY_REJECTED = 'DELETE_COMPANY_REJECTED'
const DELETE_COMPANY_FULFILLED = 'DELETE_COMPANY_FULFILLED'

// Action creators
export const fetchCompagny = (payload) => ({ type: FETCH_COMPAGNY, payload })

export const fetchCompagnysPending = () => ({
  type: FETCH_COMPAGNY_PENDING,
})
export const fetchCompagnysRejected = (payload) => ({
  type: FETCH_COMPAGNY_REJECTED,
  payload,
})
export const fetchCompagnysFulfilled = (payload) => ({
  type: FETCH_COMPAGNY_FULFILLED,
  payload,
})

export const fetchCompagnyStatus = () => ({ type: FETCH_COMPAGNYSTATUS })

export const fetchCompagnyStatusPending = () => ({
  type: FETCH_COMPAGNYSTATUS_PENDING,
})
export const fetchCompagnyStatusRejected = (payload) => ({
  type: FETCH_COMPAGNYSTATUS_REJECTED,
  payload,
})
export const fetchCompagnyStatusFulfilled = (payload) => ({
  type: FETCH_COMPAGNYSTATUS_FULFILLED,
  payload,
})

export const fetchCompagnyDetaille = () => ({ type: FETCH_COMPAGNYDETAILLE })
export const fetchCompagnyDetaillesPending = () => ({
  type: FETCH_COMPAGNYDETAILLE_PENDING,
})
export const fetchCompagnyDetaillesRejected = (payload) => ({
  type: FETCH_COMPAGNYDETAILLE_REJECTED,
  payload,
})
export const fetchCompagnyDetaillesFulfilled = (payload) => ({
  type: FETCH_COMPAGNYDETAILLE_FULFILLED,
  payload,
})

export const UpdateCompanyStatus = (payload) => ({
  type: UPDATE_COMPANYSTATUS,
  payload,
})

export const UpdateCompanyStatussPending = () => ({
  type: UPDATE_COMPANYSTATUS_PENDING,
})
export const UpdateCompanyStatussRejected = (payload) => ({
  type: UPDATE_COMPANYSTATUS_REJECTED,
  payload,
})
export const UpdateCompanyStatussFulfilled = (payload) => ({
  type: UPDATE_COMPANYSTATUS_FULFILLED,
  payload,
})

export const UpdateCompany = (payload) => ({
  type: UPDATE_COMPANY,
  payload,
})

export const UpdateCompanyPending = () => ({
  type: UPDATE_COMPANY_PENDING,
})
export const UpdateCompanyRejected = (payload) => ({
  type: UPDATE_COMPANY_REJECTED,
  payload,
})
export const UpdateCompanyFulfilled = (payload) => ({
  type: UPDATE_COMPANY_FULFILLED,
  payload,
})

export const PostCompany = (payload) => ({
  type: POST_COMPANY,
  payload,
})

export const PostCompanyPending = () => ({
  type: POST_COMPANY_PENDING,
})
export const PostCompanyRejected = (payload) => ({
  type: POST_COMPANY_REJECTED,
  payload,
})
export const PostCompanyFulfilled = (payload) => ({
  type: POST_COMPANY_FULFILLED,
  payload,
})

export const DeleteCompany = (payload) => ({
  type: DELETE_COMPANY,
  payload,
})

export const DeleteCompanyPending = () => ({
  type: DELETE_COMPANY_PENDING,
})
export const DeleteCompanyRejected = (payload) => ({
  type: DELETE_COMPANY_REJECTED,
  payload,
})
export const DeleteCompanyFulfilled = (payload) => ({
  type: DELETE_COMPANY_FULFILLED,
  payload,
})

export const EnableCompany = (payload) => ({
  type: PUT_COMPANY_enable,
  payload,
})

export const EnableCompanyPending = () => ({
  type: PUT_COMPANY_enable_PENDING,
})
export const EnableCompanyRejected = (payload) => ({
  type: PUT_COMPANY_enable_REJECTED,
  payload,
})
export const EnableCompanyFulfilled = (payload) => ({
  type: PUT_COMPANY_enable_FULFILLED,
  payload,
})

// requetes HTTP AJAX

const fetchCompagnyEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_COMPAGNY),
      mergeMap((action) =>
        ajax({
          url: action.payload
            ? `${API_GREENSANTE}/admin/companies?user_id=${action.payload}`
            : `${API_GREENSANTE}/admin/companies`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchCompagnysFulfilled(xhr.response.data.companies)),
          catchError((error) => of(fetchCompagnysRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchCompagnysPending()))

const fetchCompagnyStatusEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_COMPAGNYSTATUS),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/company/status`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchCompagnyStatusFulfilled(xhr.response.status)),
          catchError((error) => of(fetchCompagnyStatusRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchCompagnyStatusPending()))

const fetchCompagnyDetailleEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_COMPAGNYDETAILLE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/company/stats`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchCompagnyDetaillesFulfilled(xhr.response)),
          catchError((error) => of(fetchCompagnyDetaillesRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchCompagnyDetaillesPending()))

const UpdateCompanyStatusEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_COMPANYSTATUS),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/company/updatestatus`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            company_id: action.payload.company_id,
            status: action.payload.status,
          },
        }).pipe(
          map((xhr) => UpdateCompanyStatussFulfilled(xhr.response.company)),
          catchError((error) => of(UpdateCompanyStatussRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateCompanyStatussPending()))

const UpdateCompanyEpic = (action$) =>
  action$
    .pipe(
      ofType(UPDATE_COMPANY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/company`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            company_id: action.payload.company_id,
            company_name: action.payload.company_name,
            siret: action.payload.siret,
            companystatus_id: action.payload.companystatus_id,
            address_id: action.payload.address_id,
            address_info: JSON.stringify({
              name: action.payload.name,
              road_1: action.payload.road_1,
              road_2: action.payload.road_2,
              postcode: action.payload.postcode,
              city: action.payload.city,
              country: action.payload.country,
              email: action.payload.email,
              phone: action.payload.phone,
            }),
            ccn_id: action.payload.ccn_id,
            old_insurer: action.payload.old_insurer,
            effectif: action.payload.effectif,
            pricing_age_id: action.payload.pricing_age_id,
            companypourcent: action.payload.companypourcent,
            pricing_struct_tarif_id: action.payload.pricing_struct_tarif_id,
          },
        }).pipe(
          map((xhr) => UpdateCompanyFulfilled(xhr.response.message)),
          catchError((error) => of(UpdateCompanyRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateCompanyPending()))

const PostCompanyEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_COMPANY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/company/registration`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            name: action.payload.name,
            siret: action.payload.siret,
            idcc: action.payload.idcc,
            address: action.payload.address,
            postalcode: action.payload.postalcode,
            city: action.payload.city,
            effectif: action.payload.effectif,
            age_id: action.payload.age_id,
            companypourcent: action.payload.companypourcent,
            struct_tarif_id: action.payload.struct_tarif_id,
            guarantee_pack_id: action.payload.guarantee_pack_id,
          },
        }).pipe(
          map((xhr) => PostCompanyFulfilled(xhr.response.data.company)),
          catchError((error) => of(PostCompanyRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostCompanyPending()))

const DeleteCompanyEpic = (action$) =>
  action$
    .pipe(
      ofType(DELETE_COMPANY),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/company`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            company_id: action.payload,
          },
        }).pipe(
          map((xhr) => DeleteCompanyFulfilled(xhr.response.data.company)),
          catchError((error) => of(DeleteCompanyRejected(error))),
        ),
      ),
    )
    .pipe(startWith(DeleteCompanyPending()))

const EnableCompanyEpic = (action$) =>
  action$
    .pipe(
      ofType(PUT_COMPANY_enable),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/company/enable`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            company_id: action.payload,
          },
        }).pipe(
          map((xhr) => EnableCompanyFulfilled(xhr.response.data.company)),
          catchError((error) => of(EnableCompanyRejected(error))),
        ),
      ),
    )
    .pipe(startWith(UpdateCompanyPending()))

export const CompagnyEpic = combineEpics(
  fetchCompagnyEpic,
  fetchCompagnyDetailleEpic,
  fetchCompagnyStatusEpic,
  UpdateCompanyStatusEpic,
  UpdateCompanyEpic,
  PostCompanyEpic,
  DeleteCompanyEpic,
  EnableCompanyEpic,
)

const initialState = {
  entreprises: [],
  status: [],
  entrepriseDetaille: [],
  isFetching: false,
  error: {},
}

export const entrepriseReducer = (state = initialState, action) => {
  let entreprises
  switch (action.type) {
    case FETCH_COMPAGNY_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case FETCH_COMPAGNY_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case FETCH_COMPAGNY_FULFILLED:
      return { ...state, isFetching: false, entreprises: action.payload }

    case FETCH_COMPAGNYDETAILLE_PENDING:
      return {
        ...state,
        isFetching: true,
        entrepriseDetaille: initialState.entrepriseDetaille,
      }
    case FETCH_COMPAGNYDETAILLE_REJECTED:
      return {
        ...state,
        isFetching: false,
        entrepriseDetaille: initialState.entrepriseDetaille,
        error: action.payload,
      }
    case FETCH_COMPAGNYDETAILLE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        entrepriseDetaille: action.payload,
      }

    case FETCH_COMPAGNYSTATUS_PENDING:
      return {
        ...state,
        isFetching: true,
        status: initialState.status,
      }
    case FETCH_COMPAGNYSTATUS_REJECTED:
      return {
        ...state,
        isFetching: false,
        status: initialState.status,
        error: action.payload,
      }
    case FETCH_COMPAGNYSTATUS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        status: action.payload,
      }
    case UPDATE_COMPANYSTATUS_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case UPDATE_COMPANYSTATUS_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case UPDATE_COMPANYSTATUS_FULFILLED:
      entreprises = [...state.entreprises]
      entreprises.forEach((item, index) => {
        if (action.payload.company_id === item.company_id) {
          entreprises[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        entreprises,
      }

    case UPDATE_COMPANY_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case UPDATE_COMPANY_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case UPDATE_COMPANY_FULFILLED:
      entreprises = [...state.entreprises]
      entreprises.forEach((item, index) => {
        if (action.payload.company_id === item.company_id) {
          entreprises[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        entreprises: entreprises,
      }
    case POST_COMPANY_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case POST_COMPANY_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case POST_COMPANY_FULFILLED:
      entreprises = [...state.entreprises]
      entreprises.unshift(action.payload)
      return {
        ...state,
        isFetching: false,
        entreprises,
        error: null,
      }

    case DELETE_COMPANY_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case DELETE_COMPANY_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case DELETE_COMPANY_FULFILLED:
      entreprises = [...state.entreprises]
      entreprises.forEach((item, index) => {
        if (action.payload.company_id === item.company_id) {
          entreprises[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        entreprises,
      }
    case PUT_COMPANY_enable_PENDING:
      return {
        ...state,
        isFetching: true,
        entreprises: initialState.entreprises,
      }
    case PUT_COMPANY_enable_REJECTED:
      return {
        ...state,
        isFetching: false,
        entreprises: initialState.entreprises,
        error: action.payload,
      }
    case PUT_COMPANY_enable_FULFILLED:
      entreprises = [...state.entreprises]
      entreprises.forEach((item, index) => {
        if (action.payload.company_id === item.company_id) {
          entreprises[index] = action.payload
        }
      })
      return {
        ...state,
        isFetching: false,
        entreprises,
      }
    default:
      return state
  }
}
