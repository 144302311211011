import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../../config'

// ACTION TYPES
const FETCH_STRUCTTARIF = 'FETCH_STRUCTTARIF'
const FETCH_STRUCTTARIF_PENDING = 'FETCH_STRUCTTARIF_PENDING'
const FETCH_STRUCTTARIF_REJECTED = 'FETCH_STRUCTTARIF_REJECTED'
const FETCH_STRUCTTARIF_FULFILLED = 'FETCH_STRUCTTARIF_FULFILLED'

const FETCH_AGERANGE = 'FETCH_AGERANGE'
const FETCH_AGERANGE_PENDING = 'FETCH_AGERANGE_PENDING'
const FETCH_AGERANGE_REJECTED = 'FETCH_AGERANGE_REJECTED'
const FETCH_AGERANGE_FULFILLED = 'FETCH_AGERANGE_FULFILLED'

const FETCH_PRIX = 'FETCH_PRIX'
const FETCH_PRIX_PENDING = 'FETCH_PRIX_PENDING'
const FETCH_PRIX_REJECTED = 'FETCH_PRIX_REJECTED'
const FETCH_PRIX_FULFILLED = 'FETCH_PRIX_FULFILLED'

const FETCH_PRICING_TYPE = 'FETCH_PRICING_TYPE'
const FETCH_PRICING_TYPE_PENDING = 'FETCH_PRICING_TYPE_PENDING'
const FETCH_PRICING_TYPE_REJECTED = 'FETCH_PRICING_TYPE_REJECTED'
const FETCH_PRICING_TYPE_FULFILLED = 'FETCH_PRICING_TYPE_FULFILLED'

const POST_PRIX = 'POST_PRIX'
const POST_PRIX_PENDING = 'POST_PRIX_PENDING'
const POST_PRIX_REJECTED = 'POST_PRIX_REJECTED'
const POST_PRIX_FULFILLED = 'POST_PRIX_FULFILLED'

const FETCH_DEPARTEMENT = 'FETCH_DEPARTEMENT'
const FETCH_DEPARTEMENT_PENDING = 'FETCH_DEPARTEMENT_PENDING'
const FETCH_DEPARTEMENT_REJECTED = 'FETCH_DEPARTEMENT_REJECTED'
const FETCH_DEPARTEMENT_FULFILLED = 'FETCH_DEPARTEMENT_FULFILLED'

// Action creators
export const fetchStructTarif = (id) => ({
  type: FETCH_STRUCTTARIF,
  payload: id,
})
export const fetchStructTarifsPending = () => ({
  type: FETCH_STRUCTTARIF_PENDING,
})
export const fetchStructTarifsRejected = (payload) => ({
  type: FETCH_STRUCTTARIF_REJECTED,
  payload,
})
export const fetchStructTarifsFulfilled = (payload) => ({
  type: FETCH_STRUCTTARIF_FULFILLED,
  payload,
})

// Action creators
export const fetchPricingType = () => ({
  type: FETCH_PRICING_TYPE,
})
export const fetchPricingTypesPending = () => ({
  type: FETCH_PRICING_TYPE_PENDING,
})
export const fetchPricingTypesRejected = (payload) => ({
  type: FETCH_PRICING_TYPE_REJECTED,
  payload,
})
export const fetchPricingTypesFulfilled = (payload) => ({
  type: FETCH_PRICING_TYPE_FULFILLED,
  payload,
})

export const fetchAgerange = (id) => ({ type: FETCH_AGERANGE, payload: id })
export const fetchAgerangesPending = () => ({ type: FETCH_AGERANGE_PENDING })
export const fetchAgerangesRejected = (payload) => ({
  type: FETCH_AGERANGE_REJECTED,
  payload,
})
export const fetchAgerangesFulfilled = (payload) => ({
  type: FETCH_AGERANGE_FULFILLED,
  payload,
})

export const fetchPrix = (param) => ({ type: FETCH_PRIX, payload: param })
export const fetchPrixsPending = () => ({ type: FETCH_PRIX_PENDING })
export const fetchPrixsRejected = (payload) => ({
  type: FETCH_PRIX_REJECTED,
  payload,
})
export const fetchPrixsFulfilled = (payload) => ({
  type: FETCH_PRIX_FULFILLED,
  payload,
})

export const postPrix = (param) => ({ type: POST_PRIX, payload: param })
export const postPrixsPending = () => ({ type: POST_PRIX_PENDING })
export const postPrixsRejected = (payload) => ({
  type: POST_PRIX_REJECTED,
  payload,
})
export const postPrixsFulfilled = (payload) => ({
  type: POST_PRIX_FULFILLED,
  payload,
})

export const fetchDepartement = (payload) => ({
  type: FETCH_DEPARTEMENT,
  payload,
})
export const fetchDepartementsPending = () => ({
  type: FETCH_DEPARTEMENT_PENDING,
})
export const fetchDepartementsRejected = (payload) => ({
  type: FETCH_DEPARTEMENT_REJECTED,
  payload,
})
export const fetchDepartementsFulfilled = (payload) => ({
  type: FETCH_DEPARTEMENT_FULFILLED,
  payload,
})

// requetes HTTP AJAX

const fetchStructTarifEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_STRUCTTARIF),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/tools/structtarif?guarantee_pack_id=` +
            `${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchStructTarifsFulfilled(xhr.response.data.struct_tarif),
          ),
          catchError((error) => of(fetchStructTarifsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchStructTarifsPending()))

const fetchAgerangeEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_AGERANGE),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/tools/agerange?guarantee_pack_id=` +
            `${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchAgerangesFulfilled(xhr.response.data.agerange)),
          catchError((error) => of(fetchAgerangesRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchAgerangesPending()))

const fetchPrixEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_PRIX),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/admin/pricebypackbylocation?pricing_location_id=` +
            `${action.payload.pricing_location_id}` +
            `&guarantee_pack_id=` +
            `${action.payload.guarantee_pack_id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchPrixsFulfilled(xhr.response.data.prices)),
          catchError((error) => of(fetchPrixsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchPrixsPending()))

const postPrixEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_PRIX),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/price`,
          method: 'POST',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            guarantee_pack_id: action.payload.guarantee_pack_id,
            pricing_location_id: action.payload.pricing_location_id,
            pricing_type_id: action.payload.pricing_type_id,
            pricing_age_id: action.payload.pricing_age_id,
            date_year: action.payload.date_year,
            pricerate: action.payload.pricerate,
          },
        }).pipe(
          map((xhr) => postPrixsFulfilled(xhr.response)),
          catchError((error) => of(postPrixsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(postPrixsPending()))

const fetchDepartementEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_DEPARTEMENT),
      mergeMap((action) =>
        ajax({
          url:
            `${API_GREENSANTE}/tools/pricinglocation?guarantee_pack_id=` +
            `${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchDepartementsFulfilled(xhr.response.data.pricing_locations),
          ),
          catchError((error) => of(fetchDepartementsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchDepartementsPending()))

const fetchPricingTypeEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_PRICING_TYPE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/pricingType`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchPricingTypesFulfilled(xhr.response.data.pricingType),
          ),
          catchError((error) => of(fetchPricingTypesRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchPricingTypesPending()))

export const TarifEpic = combineEpics(
  fetchStructTarifEpic,
  fetchAgerangeEpic,
  fetchPrixEpic,
  postPrixEpic,
  fetchDepartementEpic,
  fetchPricingTypeEpic,
)

const initialState = {
  StructTarif: [],
  agerange: [],
  prix: [],
  pricingsType: [],
  departement: [],
  isFetching: false,
  error: null,
}

export const TarifReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STRUCTTARIF_PENDING:
      return {
        ...state,
        isFetching: true,
        StructTarif: initialState.StructTarif,
      }
    case FETCH_STRUCTTARIF_REJECTED:
      return {
        ...state,
        isFetching: false,
        StructTarif: initialState.StructTarif,
        error: action.payload,
      }
    case FETCH_STRUCTTARIF_FULFILLED:
      return { ...state, isFetching: false, StructTarif: action.payload }

    case FETCH_AGERANGE_PENDING:
      return { ...state, isFetching: true, agerange: initialState.agerange }
    case FETCH_AGERANGE_REJECTED:
      return {
        ...state,
        isFetching: false,
        agerange: initialState.agerange,
        error: action.payload,
      }
    case FETCH_AGERANGE_FULFILLED:
      return { ...state, isFetching: false, agerange: action.payload }

    case FETCH_PRIX_PENDING:
      return { ...state, isFetching: true, prix: initialState.prix }
    case FETCH_PRIX_REJECTED:
      return {
        ...state,
        isFetching: false,
        prix: initialState.prix,
        error: action.payload,
      }
    case FETCH_PRIX_FULFILLED:
      return { ...state, isFetching: false, prix: action.payload }

    // case FETCH_AGERANGE_FULFILLED:
    //   return { ...state, isFetching: false, agerange: action.payload }

    case POST_PRIX_PENDING:
      return { ...state, isFetching: true, prix: initialState.prix }
    case POST_PRIX_REJECTED:
      return {
        ...state,
        isFetching: false,
        prix: initialState.prix,
        error: action.payload,
      }
    case POST_PRIX_FULFILLED:
      return { ...state, isFetching: false, error: null }

    case FETCH_DEPARTEMENT_PENDING:
      return {
        ...state,
        isFetching: true,
        departement: initialState.departement,
      }
    case FETCH_DEPARTEMENT_REJECTED:
      return {
        ...state,
        isFetching: false,
        departement: initialState.departement,
        error: action.payload,
      }
    case FETCH_DEPARTEMENT_FULFILLED:
      return { ...state, isFetching: false, departement: action.payload }

    case FETCH_PRICING_TYPE_PENDING:
      return {
        ...state,
        isFetching: true,
        pricingsType: initialState.pricingsType,
      }
    case FETCH_PRICING_TYPE_REJECTED:
      return {
        ...state,
        isFetching: false,
        pricingsType: initialState.pricingsType,
        error: action.payload,
      }
    case FETCH_PRICING_TYPE_FULFILLED:
      return { ...state, isFetching: false, pricingsType: action.payload }

    default:
      return state
  }
}
