import { combineEpics } from 'redux-observable'
import { combineReducers } from 'redux'

/* NEW VERSION */

import entrepriseReducerGreen from '../GreenSante/modules/Entreprises'
import requestsReducerGreen from '../GreenSante/modules/Requests'
import guaranteesReducerGreen from '../GreenSante/modules/Guarantees'
import collectionsReducerGreen from '../GreenSante/modules/Collections'
import employeesReducerGreen from '../GreenSante/modules/Employees'
import contractsReducerGreen from '../GreenSante/modules/Contracts'

/* LAST VERSION */

import { garantieReducer } from './Garanties'
import { accountReducer } from './Account'
import { CompagnyEpic, entrepriseReducer } from './Entreprises'
import { PackEpic } from './Garanties'
import { accountEpic } from './Account'
import { InfoEpic, InfoReducer } from './Info'
import { UserEpic, UserReducer } from './Users'
import { TarifEpic, TarifReducer } from './admin/Tarif'
import { MessagerieEpic, messageriReducer } from './Messagerie'
import { EmailEpic, EmailReducer } from './Email'
import { FileUserEpic, FileUserReducer } from './File'



const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
}

export const changeStateReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

export const rootEpic = combineEpics(
  CompagnyEpic,
  PackEpic,
  accountEpic,
  InfoEpic,
  UserEpic,
  TarifEpic,
  MessagerieEpic,
  EmailEpic,
  FileUserEpic,
)

export const rootReducer = combineReducers({
  state: changeStateReducer,
  entreprises: entrepriseReducer,
  garanties: garantieReducer,
  account: accountReducer,
  info: InfoReducer,
  users: UserReducer,
  tarifs: TarifReducer,
  messagerie: messageriReducer,
  emails: EmailReducer,
  files: FileUserReducer,
  entreprisesGreen: entrepriseReducerGreen,
  requestsGreen: requestsReducerGreen,
  guaranteesGreen: guaranteesReducerGreen,
  collectionsGreen: collectionsReducerGreen,
  employeesGreen: employeesReducerGreen,
  contractsGreen: contractsReducerGreen
})
