import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_EMAIL = 'FETCH_EMAIL'
const FETCH_EMAIL_PENDING = 'FETCH_EMAIL_PENDING'
const FETCH_EMAIL_REJECTED = 'FETCH_EMAIL_REJECTED'
const FETCH_EMAIL_FULFILLED = 'FETCH_EMAIL_FULFILLED'

const SEND_EMAIL = 'SEND_EMAIL'
const SEND_EMAIL_PENDING = 'SEND_EMAIL_PENDING'
const SEND_EMAIL_REJECTED = 'SEND_EMAIL_REJECTED'
const SEND_EMAIL_FULFILLED = 'SEND_EMAIL_FULFILLED'

// updates ID state
const ID_ACTIVETABEMAILE = 'ID_ACTIVETABEMAILE'
const UPDATE_ERROR = 'UPDATE_ERROR'

export const fetchEmail = (payload) => ({ type: FETCH_EMAIL, payload })

export const fetchEmailsPending = () => ({
  type: FETCH_EMAIL_PENDING,
})
export const fetchEmailsRejected = (payload) => ({
  type: FETCH_EMAIL_REJECTED,
  payload,
})
export const fetchEmailsFulfilled = (payload) => ({
  type: FETCH_EMAIL_FULFILLED,
  payload,
})

export const sendEmail = (payload) => ({ type: SEND_EMAIL, payload })

export const sendEmailsPending = () => ({
  type: SEND_EMAIL_PENDING,
})
export const sendEmailsRejected = (payload) => ({
  type: SEND_EMAIL_REJECTED,
  payload,
})
export const sendEmailsFulfilled = (payload) => ({
  type: SEND_EMAIL_FULFILLED,
  payload,
})

// actions ids

export const passActivTabToEmailFiche = (payload) => ({
  type: ID_ACTIVETABEMAILE,
  payload,
})

export const updateError = () => ({
  type: UPDATE_ERROR,
})

// requetes HTTP AJAX

const fetchEmailEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_EMAIL),
      mergeMap((action) =>
        ajax({
          url: action.payload
            ? `${API_GREENSANTE}/admin/imap/email?status=${action.payload}`
            : `${API_GREENSANTE}/admin/imap/email`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchEmailsFulfilled(xhr.response.emails)),
          catchError((error) => of(fetchEmailsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchEmailsPending()))

const sendEmailEpic = (action$) =>
  action$
    .pipe(
      ofType(SEND_EMAIL),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/imap/email/send`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            emaile_id: action.payload.email_id,
            from: action.payload.from,
            Destinataire: action.payload.Destinataire,
            subject: action.payload.subject,
            message: action.payload.message,
          },
        }).pipe(
          map((xhr) => sendEmailsFulfilled(xhr.response.info)),
          catchError((error) => of(sendEmailsRejected(error))),
        ),
      ),
    )
    .pipe(startWith(sendEmailsPending()))

const initialState = {
  emails: [],
  emaile: {},
  activeTabEmail: 0,
  isFetching: false,
  error: null,
}

export const EmailEpic = combineEpics(fetchEmailEpic, sendEmailEpic)

export const EmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_PENDING:
      return {
        ...state,
        isFetching: true,
        emails: initialState.emails,
      }
    case FETCH_EMAIL_REJECTED:
      return {
        ...state,
        isFetching: false,
        emails: initialState.emails,
        error: action.payload,
      }
    case FETCH_EMAIL_FULFILLED:
      return {
        ...state,
        isFetching: false,
        emails: action.payload,
      }

    case SEND_EMAIL_PENDING:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case SEND_EMAIL_REJECTED:
      // let emails = [...state.emails]
      // emails.forEach((item, index) => {
      //   if (action.payload.id === item.id) {
      //     emails[index] = action.payload
      //   }
      // })
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case SEND_EMAIL_FULFILLED:
      return {
        ...state,
        isFetching: false,
        error: 'no error send email',
      }

    case ID_ACTIVETABEMAILE:
      return {
        ...state,
        activeTabEmail: action.payload.id,
        emaile: action.payload,
      }

    case UPDATE_ERROR:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
