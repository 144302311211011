import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'


const locationHelper = locationHelperBuilder({})



export const userIsNotAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false,
    authenticatedSelector: state => localStorage.getItem('token') === null,
    wrapperDisplayName: 'UserIsNotAuthenticated'
  })
  
 export const userIsAuthenticated = connectedRouterRedirect({
    redirectPath: '/Login',
    authenticatedSelector: state => localStorage.getItem('token') !== null,
    wrapperDisplayName: 'UserIsAuthenticated'
  })
  
  export const logoutUser = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => localStorage.removeItem('token'),
    wrapperDisplayName: 'LogoutUser'
  })
  
  export const redirectDashboard = connectedRouterRedirect({
    redirectPath: '/dashboard',
    authenticatedSelector: state => localStorage.getItem('token') !== null,
    wrapperDisplayName: 'redirectDashboard'
  })