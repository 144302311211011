import { createAsyncThunk, } from '@reduxjs/toolkit'
import axios from 'axios'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getEmployees = createAsyncThunk('green_sante/employees/get', async () => {
    const { data } = await axios.get("/admin/employees", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanies = createAsyncThunk('green_sante/employees/companies/get', async () => {
    const { data } = await axios.get("/admin/companies", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCollegesTypes = createAsyncThunk('green_sante/employees/collegetypes', async () => {
    const { data } = await axios.get(`admin/company/collegestypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyContracts = createAsyncThunk('green_sante/employees/contracts_by_company_id', async (id) => {
    const { data } = await axios.get(`/admin/company/${id}/contracts`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateEmployee = createAsyncThunk('green_sante/employees/create_employee', async (body) => {
    const { data } = await axios.post(`/admin/employees`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const getCompanyEmployee = createAsyncThunk('green_sante/employees/employee/get_employee', async (userId) => {
    const { data } = await axios.get(`/admin/companies/${userId}/employee`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getEmployeeContracts = createAsyncThunk('green_sante/employees/employee/get_employee_contracts', async (userId) => {
    const { data } = await axios.get(`/admin/employee/${userId}/contracts `, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getEmployeeDocuments = createAsyncThunk('green_sante/employees/employee/get_employee_documents', async (userId) => {
    const { data } = await axios.get(`/admin/employee/${userId}/documents `, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getDocumentsFileType = createAsyncThunk('green_sante/employee/documents/filetype', async () => {
    const { data } = await axios.get(`/admin/company/documents/filestypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateEmployeeContract = createAsyncThunk('green_sante/employees/create_employee_contract', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/employee/contract`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data)
    }
})

export const getContractFileTypes = createAsyncThunk('green_sante/employee/contractfiletype', async (risk_id) => {
    const { data } = await axios.get(`/admin/companies/${risk_id}/contractfiletype`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const patchUpdateEmployeeInfos = createAsyncThunk('green_sante/employees/update_employee_infos', async (body) => {
    const { data } = await axios.patch(`/admin/employees`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const patchUpdateEmployeeContract = createAsyncThunk('green_sante/employee/contracts/update', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/employee/contract`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const postFileDocumentsEmployee = createAsyncThunk('green_sante/employee/documents/create', async (formData) => {
    const { data } = await axios.post(`/admin/employee/documents`, formData,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'multipart/form-data'
            }
        })
    return data
})

export const getEmployeeNote = createAsyncThunk('green_sante/employee/get_note', async (user_id) => {
    const { data } = await axios.get(`/admin/employees/${user_id}/note`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateEmployeeNote = createAsyncThunk('green_sante/employee/note/create_note', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/employees/note`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const postCreateEmployeeRecipient = createAsyncThunk('green_sante/employee/createRecipient', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/recipient`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getRecipientTypes = createAsyncThunk('green_sante/employees/recipientsTypes', async () => {
    const { data } = await axios.get("/admin/recipientTypes", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getContractTypes = createAsyncThunk('green_sante/employees/contracttype', async () => {
    const { data } = await axios.get(`admin/contracttypes `, { headers: { Authorization: tokenHeader() } })
    return data
})

export const patchUpdateEmployeeRecipient = createAsyncThunk('green_sante/employee/updateRecipient', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`/admin/recipient`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})