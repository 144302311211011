import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { API_GREENSANTE } from '../config'

const FETCH_FILE_USER = 'FETCH_FILE_USER'
const FETCH_FILE_USER_PENDING = 'FETCH_FILE_USER_PENDING'
const FETCH_FILE_USER_REJECTED = 'FETCH_FILE_USER_REJECTED'
const FETCH_FILE_USER_FULFILLED = 'FETCH_FILE_USER_FULFILLED'

export const fetchFileUser = (payload) => ({ type: FETCH_FILE_USER, payload })

export const fetchFileUsersPending = () => ({
  type: FETCH_FILE_USER_PENDING,
})
export const fetchFileUsersRejected = (payload) => ({
  type: FETCH_FILE_USER_REJECTED,
  payload,
})
export const fetchFileUsersFulfilled = (payload) => ({
  type: FETCH_FILE_USER_FULFILLED,
  payload,
})

const fetchFileUserEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_FILE_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/user/file/${action.payload}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchFileUsersFulfilled(xhr.response.data.files)),
          catchError((error) => of(fetchFileUsersRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchFileUsersPending()))

const initialState = {
  filesUsers: [],
  isFetching: false,
  error: null,
}

export const FileUserEpic = combineEpics(fetchFileUserEpic)

export const FileUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILE_USER_PENDING:
      return {
        ...state,
        isFetching: true,
        filesUsers: initialState.filesUsers,
      }
    case FETCH_FILE_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        filesUsers: initialState.filesUsers,
        error: action.payload,
      }
    case FETCH_FILE_USER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        filesUsers: action.payload,
      }

    default:
      return state
  }
}
