
import { toastSuccess, toastError } from '../toast'

import {
    getCompanies,
    getCompanyDashboard,
    getCompanyContracts,
    getCompanyEmployees,
    getCompanyEmployee,
    patchEditCompanyContract,
    getCollections,
    postCreateCompanyContract,
    getContractFileTypes,
    postFilesContract,
    patchUpdateCompany,
    getCompanyDocuments,
    getDocumentsFileType,
    postFileDocuments,
    getStructsTarif,
    getCollegesTypes,
    deleteCompanyContractFile,
    getRiskTypes,
    getCCN,
    postEntreprisesCreate,
    getEntrepriseInfosBySiret,
    getPricingAges,
    getDeparments,
    getContributionsBase,
    getPricingTypes,
    getCompanyContributionsBase,
    getAllUsers,
    postCompanyInterlocutorCreate,
    postCompanyRHCreate,
    patchUpdateEmployeeInfos,
    postValidateEmployee,
    getCompanyNote,
    postCreateCompanyNote,
    deleteCompanyRH,
    deleteCompanyInterlocutor,
    patchUpdateFilesEnable,
    getContractTypes,
    postRequestAffiliation,
    patchEditInterlocutor
} from './entreprisesChunk'

export const getCompaniesExtra = {
    [getCompanies.pending]: (state) => {
        state.loading = true
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.entreprises = payload?.data?.companies
    },
    [getCompanies.rejected]: (state) => {
        state.loading = false
    },
}

export const getCompanyDashboardExtra = {
    [getCompanyDashboard.pending]: (state) => {
        state.loading = true
    },
    [getCompanyDashboard.fulfilled]: (state, { payload }) => {
        const { dashboard, identity, ccn } = payload.data
        state.loading = false
        state.entreprise.dashboard = dashboard
        state.entreprise.identity = identity
        state.entreprise.ccn = ccn.map((el) => {
            return {
                value: el.ccn_id,
                label: el.name,
            };
        })
    },
    [getCompanyDashboard.rejected]: (state) => {
        state.loading = false
    },
}

export const getCompanyContractsExtra = {
    [getCompanyContracts.pending]: (state) => {
        state.contracts.loading = true
    },
    [getCompanyContracts.fulfilled]: (state, { payload }) => {
        state.contracts.loading = false
        state.contracts.refresh = false
        state.contracts.data = payload.contracts
        state.contracts.data.forEach(contract => {
            contract.show = false
            contract.label = contract.number
            contract.value = contract.contract_company_id
        });
    },
    [getCompanyContracts.rejected]: (state) => {
        state.contracts.loading = false
    },
}

export const getCompanyEmployeesExtra = {
    [getCompanyEmployees.pending]: (state) => {
        state.loading = true
    },
    [getCompanyEmployees.fulfilled]: (state, { payload }) => {
        state.loading = false
        payload.employees.forEach(employee => {
            employee.label = employee.nameEmployment
            employee.value = employee.user_id
        });
        state.employees = payload.employees
    },
    [getCompanyEmployees.rejected]: (state) => {
        state.loading = false
        toastError("Erreur durant la récupération des salariés de l'entreprise")
    },
}

export const getCompanyEmployeeExtra = {
    [getCompanyEmployee.pending]: (state) => {
        state.loading = true
    },
    [getCompanyEmployee.fulfilled]: (state, { payload }) => {
        state.loading = false
        payload.employee.recipients.forEach(recipient => {
            recipient.show = false
        });
        state.employee = { ...state.employee, ...payload.employee }
    },
    [getCompanyEmployee.rejected]: (state) => {
        state.loading = false
    },
}

export const patchEditCompanyContractExtra = {
    [patchEditCompanyContract.pending]: (state) => {
    },
    [patchEditCompanyContract.fulfilled]: (state, { payload }) => {
        state.contract = {}
        toastSuccess(payload.message);
    },
    [patchEditCompanyContract.rejected]: (state, { payload }) => {
        state.contract = {}
        toastError(payload || "Erreur durant la modification du contrat");
    },
}

export const getCollectionsExtra = {
    [getCollections.pending]: (state) => {
    },
    [getCollections.fulfilled]: (state, { payload }) => {
        payload?.collections?.forEach(collection => {
            const structs = collection?.struct_tarifs
            collection.value = collection.collection_id
            collection.label = collection.name
            if (structs.length > 0) {
                collection.struct_tarifs = collection?.struct_tarifs.map((st) => {
                    return {
                        value: st.pricing_struct_tarif_id,
                        label: st.name,
                        ...st,
                    }
                })
            }
        });
        state.collections = payload?.collections
    },
    [getCollections.rejected]: (state) => {
        toastError("Erreur durant la récupération des gammes");
    },
}

export const getContractFileTypesExtra = {
    [getContractFileTypes.pending]: (state) => {
    },
    [getContractFileTypes.fulfilled]: (state, { payload }) => {
        state.contractFileTypes = payload.contractfiles
    },
    [getContractFileTypes.rejected]: (state) => {
    },
}

export const postCreateCompanyContractExtra = {
    [postCreateCompanyContract.pending]: (state) => {
        state.modalCreateContract.loading = true
    },
    [postCreateCompanyContract.fulfilled]: (state, { payload }) => {
        state.modalCreateContract.loading = false
        state.modalCreateContract.step2 = true
        state.modalCreateContract.step1 = false
        state.contract = payload.contract
        toastSuccess(`Le contrat ${payload.contract.number} à bién été crée, vous pouvez maintenant upload des fichiers`);
    },
    [postCreateCompanyContract.rejected]: (state) => {
        state.modalCreateContract.loading = false
    },
}

export const postFilesContractExtra = {
    [postFilesContract.pending]: () => {
    },
    [postFilesContract.fulfilled]: () => {
        toastSuccess(`Les fichiers ont bien été upload`);
    },
    [postFilesContract.rejected]: () => {
        toastError(`Les fichiers n'ont pas été upload`);
    },
}

export const patchUpdateCompanyExtra = {
    [patchUpdateCompany.pending]: (state) => {
        state.loading = true
    },
    [patchUpdateCompany.fulfilled]: (state, { payload }) => {
        state.loading = false
        toastSuccess(payload.message);
    },
    [patchUpdateCompany.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const getCompanyDocumentsExtra = {
    [getCompanyDocuments.pending]: (state) => {
        state.documents.loading = true
    },
    [getCompanyDocuments.fulfilled]: (state, { payload }) => {
        state.documents.loading = false
        state.documents.data = payload.files
    },
    [getCompanyDocuments.rejected]: (state) => {
        state.documents.loading = false
    },
}

export const getDocumentsFileTypeExtra = {
    [getDocumentsFileType.pending]: (state) => {
    },
    [getDocumentsFileType.fulfilled]: (state, { payload: { data } }) => {
        state.documents.select = data.map((el) => {
            return {
                value: el.filetype_id,
                label: el.name,
            };
        })
    },
    [getDocumentsFileType.rejected]: () => {
        toastError("Erreur durant la récupération des types de fichiers");
    },
}

export const postFileDocumentsExtra = {
    [postFileDocuments.pending]: (state) => {
        state.documents.loading = true
    },
    [postFileDocuments.fulfilled]: (state, action) => {
        state.documents.loading = false
        toastSuccess(`Le fichier à bien été upload`);
    },
    [postFileDocuments.rejected]: (state) => {
        state.documents.loading = false
        toastError("Le fichier n'à pas été upload")
    },
}

export const getStructsTarifExtra = {
    [getStructsTarif.pending]: (state) => {
    },
    [getStructsTarif.fulfilled]: (state, { payload: { data } }) => {
        state.structstarif = data
    },
    [getStructsTarif.rejected]: () => {
        toastError("Erreur durant la récupération des structures tarifaire");
    },
}

export const getCollegesTypesExtra = {
    [getCollegesTypes.pending]: (state) => {
    },
    [getCollegesTypes.fulfilled]: (state, { payload: { data } }) => {
        state.collegestypes = data
    },
    [getCollegesTypes.rejected]: () => {
        toastError("Erreur durant la récupération des colleges types");
    },
}

export const getRiskTypesExtra = {
    [getRiskTypes.pending]: (state) => {
    },
    [getRiskTypes.fulfilled]: (state, { payload: { data } }) => {
        state.risktypes = data
    },
    [getRiskTypes.rejected]: () => {
        toastError("Erreur durant la récupération des risk types");
    },
}

export const deleteCompanyContractFileExtra = {
    [deleteCompanyContractFile.pending]: (state) => {
    },
    [deleteCompanyContractFile.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [deleteCompanyContractFile.rejected]: () => {
        toastError("Erreur durant la surpression du fichier");
    },
}

export const getCCNExtra = {
    [getCCN.pending]: (state) => {
    },
    [getCCN.fulfilled]: (state, { payload: { data } }) => {
        state.ccn = data.map((el) => {
            return {
                value: el.ccn_id,
                label: el.name,
                ...el
            };
        })
    },
    [getCCN.rejected]: () => {
        toastError("Erreur durant la récupération des IDCC - Convention Collective");
    },
}

export const postEntreprisesCreateExtra = {
    [postEntreprisesCreate.pending]: () => {
    },
    [postEntreprisesCreate.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message);
    },
    [postEntreprisesCreate.rejected]: () => {
        toastError(`Erreur durant la création de l'enreprise`);
    },
}

export const getEntrepriseInfosBySiretExtra = {
    [getEntrepriseInfosBySiret.pending]: (state) => {
        state.siretLoading = true
    },
    [getEntrepriseInfosBySiret.fulfilled]: (state, { payload }) => {
        state.siretLoading = false
    },
    [getEntrepriseInfosBySiret.rejected]: (state, payload) => {
        state.siretLoading = false
        toastError(`Nous avons pas trouvé d'informations d'entreprise sur le numéros de siret [${payload.meta.arg}]`);
    },
}

export const getPricingAgesExtra = {
    [getPricingAges.pending]: (state) => {
    },
    [getPricingAges.fulfilled]: (state, { payload: { data } }) => {
        state.pricingAges = data.map((el) => {
            return {
                value: el.pricing_age_id,
                label: el.name,
                ...el,
            };
        })
    },
    [getPricingAges.rejected]: () => {
        toastError("Erreur durant la récupération des prix par ages");
    },
}

export const getDeparmentsExtra = {
    [getDeparments.pending]: (state) => {
    },
    [getDeparments.fulfilled]: (state, { payload: { data } }) => {
        state.departments = data.map((el) => {
            return {
                value: el.pricing_location_id,
                label: el.name,
                ...el,
            };
        })
    },
    [getDeparments.rejected]: () => {
        toastError("Erreur durant la récupération des départements");
    },
}

export const getContributionsBaseExtra = {
    [getContributionsBase.pending]: (state) => {
    },
    [getContributionsBase.fulfilled]: (state, { payload: { data } }) => {
        state.contributionsBase = data.map((el) => {
            return {
                value: el.pricing_contribution_base_id,
                label: el.name,
                ...el,
            };
        })
    },
    [getContributionsBase.rejected]: () => {
        toastError("Erreur durant la récupération des contributions basiques");
    },
}

export const getPricingTypesExtra = {
    [getPricingTypes.pending]: (state) => {
    },
    [getPricingTypes.fulfilled]: (state, { payload: { data } }) => {
        state.pricingtypes = data
    },
    [getPricingTypes.rejected]: () => {
        toastError("Erreur durant la récupération des pricings types");
    },
}

export const getCompanyContributionsBaseExtra = {
    [getCompanyContributionsBase.pending]: (state) => {
    },
    [getCompanyContributionsBase.fulfilled]: (state, { payload: { data } }) => {
        state.companyContributionsBase = data.map((el) => {
            return {
                value: el.pricing_company_contribution_base_id,
                label: el.name,
                ...el,
            };
        })
    },
    [getCompanyContributionsBase.rejected]: () => {
        toastError("Erreur durant la récupération des contributions d'entreprise disponible");
    },
}

export const getAllUsersExtra = {
    [getAllUsers.pending]: (state) => {
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
        payload.employees.forEach(employee => {
            employee.label = employee.nameEmployment
            employee.value = employee.user_id
        });
        state.allusers = payload?.employees
    },
    [getAllUsers.rejected]: () => {
        toastError("Erreur durant la récupération de tous les utilisateurs de Green Santé");
    },
}

export const postCompanyInterlocutorCreateExtra = {
    [postCompanyInterlocutorCreate.pending]: () => {
    },
    [postCompanyInterlocutorCreate.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message);
    },
    [postCompanyInterlocutorCreate.rejected]: () => {
        toastError(`Erreur durant la création de l'interlocuteur`);
    },
}

export const postCompanyRHCreateExtra = {
    [postCompanyRHCreate.pending]: () => {
    },
    [postCompanyRHCreate.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message);
    },
    [postCompanyRHCreate.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const patchUpdateEmployeeInfosExtra = {
    [patchUpdateEmployeeInfos.pending]: (state) => {
    },
    [patchUpdateEmployeeInfos.fulfilled]: (state, { payload }) => {
        toastSuccess('Le salarié à bien été mis à jour')
        state.employee = {}
    },
    [patchUpdateEmployeeInfos.rejected]: (state) => {
    },
}

export const postValidateEmployeeExtra = {
    [postValidateEmployee.pending]: () => {
    },
    [postValidateEmployee.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message);
    },
    [postValidateEmployee.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const getCompanyNoteExtra = {
    [getCompanyNote.pending]: (state) => {
        state.note.loading = true
    },
    [getCompanyNote.fulfilled]: (state, { payload }) => {
        state.note.loading = false
        state.note.html = payload.note.note
    },
    [getCompanyNote.rejected]: (state, { payload }) => {
        state.note.loading = false
        toastError(payload);
    },
}

export const postCreateCompanyNoteExtra = {
    [postCreateCompanyNote.pending]: () => {
    },
    [postCreateCompanyNote.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postCreateCompanyNote.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const deleteCompanyRHExtra = {
    [deleteCompanyRH.pending]: (state) => {
    },
    [deleteCompanyRH.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [deleteCompanyRH.rejected]: () => {
        toastError("Erreur durant la supression de l'accès RH");
    },
}

export const deleteCompanyInterlocutorExtra = {
    [deleteCompanyInterlocutor.pending]: (state) => {
    },
    [deleteCompanyInterlocutor.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [deleteCompanyInterlocutor.rejected]: () => {
        toastError("Erreur durant la surpression de l'interlocuteur");
    },
}

export const patchUpdateFilesEnableExtra = {
    [patchUpdateFilesEnable.pending]: () => {
    },
    [patchUpdateFilesEnable.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [patchUpdateFilesEnable.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const getContractTypesExtra = {
    [getContractTypes.pending]: (state) => {
    },
    [getContractTypes.fulfilled]: (state, { payload: { data } }) => {
        state.contracttypes = data
    },
    [getContractTypes.rejected]: () => {
        toastError("Erreur durant la récupération des types de contrats");
    },
}

export const postRequestAffiliationExtra = {
    [postRequestAffiliation.pending]: () => {
    },
    [postRequestAffiliation.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postRequestAffiliation.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}


export const patchEditInterlocutorExtra = {
    [patchEditInterlocutor.pending]: () => {
    },
    [patchEditInterlocutor.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [patchEditInterlocutor.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}