import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios'

import _ from 'lodash'

import { toastError, toastSuccess } from '../toast'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getCollections = createAsyncThunk('green_sante/collections/get', async () => {
    const { data } = await axios.get("admin/collections", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getInsurers = createAsyncThunk('green_sante/collections/get_insurers', async () => {
    const { data } = await axios.get("admin/insurers", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getGuarantees = createAsyncThunk('green_sante/collections/get_guarantees', async () => {
    const { data } = await axios.get("admin/guaranteesPack", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCollegesTypes = createAsyncThunk('green_sante/collections/collegetypes', async () => {
    const { data } = await axios.get(`admin/company/collegestypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getStructTarifs = createAsyncThunk('green_sante/collections/structs_tarifs', async () => {
    const { data } = await axios.get(`admin/structTarifs`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getContributionsBase = createAsyncThunk('green_sante/collections/contributionsBase', async () => {
    const { data } = await axios.get(`admin/contributionsBase`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyContributionsBase = createAsyncThunk('green_sante/collections/company_contributionsBase', async () => {
    const { data } = await axios.get(`admin/companyContributionsBase`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCollectionsTypes = createAsyncThunk('green_sante/collections/collectionstypes', async () => {
    const { data } = await axios.get(`admin/collections_types`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getOptionsContributionsBase = createAsyncThunk('green_sante/collections/company_contributionsBase', async () => {
    const { data } = await axios.get(`admin/optionsContributionsBase`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getPricingTypes = createAsyncThunk('green_sante/collections/pricing_types', async () => {
    const { data } = await axios.get(`admin/pricingTypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateCollection = createAsyncThunk('green_sante/collections/create', async (body) => {
    const { data } = await axios.post(`admin/collections`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const patchUpdateCollection = createAsyncThunk('green_sante/collections/update', async (body) => {
    const { data } = await axios.patch(`admin/collections`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const getDeparments = createAsyncThunk('green_sante/collections/departments', async () => {
    const { data } = await axios.get(`admin/departments`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getPricingAges = createAsyncThunk('green_sante/collections/pricingAges', async () => {
    const { data } = await axios.get(`admin/pricingAges`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postPriceRate = createAsyncThunk('green_sante/collections/priceRate', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/pricerate`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getCollectionsInfos = createAsyncThunk('green_sante/collections/collections_infos', async ({ collection_id, year }) => {
    const { data } = await axios.get(`/admin/collectioninfo?collection_id=${collection_id}&year=${year}`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getPriceByCollection = createAsyncThunk('green_sante/collections/collections_prices', async ({ collection_id, year, pricing_location_id }) => {
    const { data } = await axios.get(`/admin/pricebycollectionlocation?collection_id=${collection_id}&year=${year}&pricing_location_id=${pricing_location_id}`,
        { headers: { Authorization: tokenHeader() } })
    return data
})

export const deleteInfosCollection = createAsyncThunk('green_sante/collections/collections_delete_infos', async ({
    collection_id,
    year,
    pricing_location_id,
    pricing_age_id,
    struct_tarif_id
}) => {
    const { data } = await axios.delete(`/admin/pricerate?collection_id=${collection_id}&year=${year}&pricing_location_id=${pricing_location_id}&pricing_age_id=${pricing_age_id}&struct_tarif_id=${struct_tarif_id}`,
        { headers: { Authorization: tokenHeader() } })
    return data
})


export const postCopyByYear = createAsyncThunk('green_sante/collections/copybyyear', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/price/copybyyear`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState: {
        collections: [],
        collection: {},
        loading: false,
        insurers: [],
        guarantees: [],
        collegestypes: [],
        structstarifs: [],
        pricingtypes: [],
        contributionsBase: [],
        companyContributionsBase: [],
        optionsContributionsBase: [],
        collectionstypes: [],
        departments: [],
        pricingAges: [],

        collection_infos: {},
        collection_prices: []
    },
    reducers: {
        setCollection: (state, { payload }) => {
            state.collection = payload
        },
        setCollections: (state, { payload }) => {
            state.collections = payload
        },
        setCollectionsPrices: (state, { payload }) => {
            if (payload === 'filter') {
                const finalArr = _.cloneDeep(current(state.collection_prices))
                finalArr.forEach((actualPrice, idx1) => {
                    finalArr.forEach((checkPrice, idx2) => {
                        if (actualPrice.prices[0].pricing_location_id === checkPrice.prices[0].pricing_location_id && idx1 !== idx2) {
                            finalArr.splice(idx1, 1)
                        }
                    })
                })
                state.collection_prices = finalArr
            } else {
                state.collection_prices = payload
            }
        },
        setCollectionsLastYears: (state, { payload }) => {
            const tmp2 = current(state.collections).map((c) => ({ ...c }));
            tmp2.forEach((c) => {
                const tmp3 = c.collection_options.map((c) => ({ ...c }));
                tmp3.forEach((op) => {
                    if (op.collection_id === payload.collection_id) {
                        op.lastyearprice = payload.year
                        state.collection = op
                    }
                })
                c.collection_options = tmp3
                if (c.collection_id === payload.collection_id) {
                    c.lastyearprice = payload.year
                    state.collection = c
                }
            })
            state.collections = tmp2
        },
    },
    extraReducers: {
        [getCollections.pending]: (state) => {
            state.loading = true
        },
        [getCollections.fulfilled]: (state, { payload }) => {
            state.loading = false
            payload?.collections?.forEach(collection => {
                collection.show = false
                collection.value = collection.collection_id
                collection.label = collection.name
                if (collection.collection_options.length > 0) {
                    (collection.collection_options.forEach((option) => {
                        option.parent_collection_id = collection.collection_id
                        option.parent_collection_type_id = collection.collection_type_id
                    }))
                }
            });
            state.collections = payload?.collections
        },
        [getCollections.rejected]: (state) => {
            state.loading = false
            toastError("Erreur durant la récupération des gammes")
        },

        [getInsurers.pending]: (state) => {
        },
        [getInsurers.fulfilled]: (state, { payload: { insurers } }) => {
            state.insurers = insurers.map((el) => {
                return {
                    value: el.insurer_id,
                    label: el.name,
                    insurer_type_id: el.insurer_type_id,
                    insurer_id: el.insurer_id
                };
            })
        },
        [getInsurers.rejected]: (state) => {
            toastError("Erreur durant la récupération des assureurs")
        },

        [getGuarantees.pending]: (state) => {
        },
        [getGuarantees.fulfilled]: (state, { payload: { data } }) => {
            state.guarantees = data.map((el) => {
                return {
                    value: el.guarantee_pack_id,
                    label: el.name,
                    guarantee_pack_id: el.guarantee_pack_id,
                    risk_type: el.risk_type,
                    risk_type_id: el.risk_type_id
                };
            })
        },
        [getGuarantees.rejected]: (state) => {
            toastError("Erreur durant la récupération des packs de garanties")
        },

        [getCollegesTypes.pending]: (state) => {
        },
        [getCollegesTypes.fulfilled]: (state, { payload: { data } }) => {
            state.collegestypes = data
        },
        [getCollegesTypes.rejected]: () => {
            toastError("Erreur durant la récupération des colleges types");
        },

        [getStructTarifs.pending]: (state) => {
        },
        [getStructTarifs.fulfilled]: (state, { payload: { data } }) => {
            state.structstarifs = data.map((el) => {
                return {
                    value: el.pricing_struct_tarif_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getStructTarifs.rejected]: () => {
            toastError("Erreur durant la récupération des structures tarifaires");
        },

        [getContributionsBase.pending]: (state) => {
        },
        [getContributionsBase.fulfilled]: (state, { payload: { data } }) => {
            state.contributionsBase = data.map((el) => {
                return {
                    value: el.pricing_contribution_base_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getContributionsBase.rejected]: () => {
            toastError("Erreur durant la récupération des contributions basiques");
        },

        [getCompanyContributionsBase.pending]: (state) => {
        },
        [getCompanyContributionsBase.fulfilled]: (state, { payload: { data } }) => {
            state.companyContributionsBase = data.map((el) => {
                return {
                    value: el.pricing_company_contribution_base_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getCompanyContributionsBase.rejected]: () => {
            toastError("Erreur durant la récupération des contributions d'entreprise disponible");
        },

        [getCollectionsTypes.pending]: (state) => {
        },
        [getCollectionsTypes.fulfilled]: (state, { payload: { data } }) => {
            state.collectionstypes = data.map((el) => {
                return {
                    value: el.collection_type_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getCollectionsTypes.rejected]: () => {
            toastError("Erreur durant la récupération des types de collections");
        },

        [getOptionsContributionsBase.pending]: (state) => {
        },
        [getOptionsContributionsBase.fulfilled]: (state, { payload: { data } }) => {
            state.optionsContributionsBase = data.map((el) => {
                return {
                    value: el.pricing_option_contribution_base_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getOptionsContributionsBase.rejected]: () => {
            toastError("Erreur durant la récupération des contributions d'options disponible");
        },

        [getPricingTypes.pending]: (state) => {
        },
        [getPricingTypes.fulfilled]: (state, { payload: { data } }) => {
            state.pricingtypes = data
        },
        [getPricingTypes.rejected]: () => {
            toastError("Erreur durant la récupération des pricings types");
        },

        [postCreateCollection.pending]: (state) => {
        },
        [postCreateCollection.fulfilled]: (state, { payload: { message } }) => {
            toastSuccess(message)
        },
        [postCreateCollection.rejected]: () => {
            toastError("Erreur durant la création de la gamme");
        },

        [patchUpdateCollection.pending]: (state) => {
        },
        [patchUpdateCollection.fulfilled]: (state, { payload: { message } }) => {
            toastSuccess(message)
        },
        [patchUpdateCollection.rejected]: () => {
            toastError("Erreur durant la création de la gamme");
        },


        [getDeparments.pending]: (state) => {
        },
        [getDeparments.fulfilled]: (state, { payload: { data } }) => {
            state.departments = data.map((el) => {
                return {
                    value: el.pricing_location_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getDeparments.rejected]: () => {
            toastError("Erreur durant la récupération des départements");
        },

        [getPricingAges.pending]: (state) => {
        },
        [getPricingAges.fulfilled]: (state, { payload: { data } }) => {
            state.pricingAges = data.map((el) => {
                return {
                    value: el.pricing_age_id,
                    label: el.name,
                    ...el,
                };
            })
        },
        [getPricingAges.rejected]: () => {
            toastError("Erreur durant la récupération des prix par ages");
        },

        [postPriceRate.pending]: (state) => {
        },
        [postPriceRate.fulfilled]: (state, { payload: { message } }) => {
            toastSuccess(message)
        },
        [postPriceRate.rejected]: () => {
            toastError("Erreur durant la mise à jour des prix");
        },

        [getCollectionsInfos.pending]: (state) => {
        },
        [getCollectionsInfos.fulfilled]: (state, { payload: { data } }) => {
            state.collection_infos = data
        },
        [getCollectionsInfos.rejected]: () => {
            toastError("Erreur durant la récupération des informations de la collection");
        },

        [getPriceByCollection.pending]: (state) => {
        },
        [getPriceByCollection.fulfilled]: (state, { payload: { data } }) => {
            state.collection_prices.push(data)
            state.collection_prices = state.collection_prices.filter((c) => c?.prices !== false)
        },
        [getPriceByCollection.rejected]: () => {
            toastError("Erreur durant la récupération des prix de la collection");
        },

        [deleteInfosCollection.pending]: (state) => {
        },
        [deleteInfosCollection.fulfilled]: (state, { payload: { message } }) => {
            toastSuccess(message)
        },
        [deleteInfosCollection.rejected]: () => {
            toastError("Erreur durant le delete");
        },

        [postCopyByYear.pending]: (state) => {
        },
        [postCopyByYear.fulfilled]: (state, { payload: { message } }) => {
            toastSuccess(message)
        },
        [postCopyByYear.rejected]: () => {
            toastError("Erreur durant la copie");
        },
    }
})

export const { setCollection, setCollections, setInitStandard, setCollectionsPrices, setCollectionsLastYears } = collectionsSlice.actions

export default collectionsSlice.reducer