import { createAsyncThunk, } from '@reduxjs/toolkit'
import axios from 'axios'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getCompanies = createAsyncThunk('green_sante/entreprises', async () => {
    const { data } = await axios.get("/admin/companies", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyDashboard = createAsyncThunk('green_sante/entreprise/dashboard', async (id) => {
    const { data } = await axios.get(`/admin/companies/${id}/dashboard`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyContracts = createAsyncThunk('green_sante/entreprise/contracts', async (id) => {
    const { data } = await axios.get(`/admin/company/${id}/contracts`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyEmployees = createAsyncThunk('green_sante/entreprise/employees', async (id) => {
    const { data } = await axios.get(`/admin/companies/${id}/listemployees`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyEmployee = createAsyncThunk('green_sante/entreprise/employee/user', async (userId) => {
    const { data } = await axios.get(`/admin/companies/${userId}/employee`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const patchEditCompanyContract = createAsyncThunk('green_sante/entreprise/contracts/edit', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`/admin/company/contracts`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getCollections = createAsyncThunk('green_sante/entreprise/collections', async () => {
    const { data } = await axios.get(`/admin/collections`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getContractFileTypes = createAsyncThunk('green_sante/entreprise/contractfiletype', async (risk_id) => {
    const { data } = await axios.get(`/admin/companies/${risk_id}/contractfiletype`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateCompanyContract = createAsyncThunk('green_sante/entreprise/contract/create', async (body) => {
    const { data } = await axios.post(`/admin/company/contract`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const patchUpdateCompany = createAsyncThunk('green_sante/entreprise/company/update', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/company`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const postFilesContract = createAsyncThunk('green_sante/entreprise/contracts/files', async (formData) => {
    const { data } = await axios.post(`/admin/company/contracts/uploadcontract`, formData,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'multipart/form-data'
            }
        })
    return data
})

export const getCompanyDocuments = createAsyncThunk('green_sante/entreprise/documents', async (id) => {
    const { data } = await axios.get(`/admin/companies/${id}/files`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getDocumentsFileType = createAsyncThunk('green_sante/entreprise/documents/filetype', async () => {
    const { data } = await axios.get(`/admin/company/documents/filestypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postFileDocuments = createAsyncThunk('green_sante/entreprise/documents/create', async (formData) => {
    const { data } = await axios.post(`/admin/company/documents`, formData,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'multipart/form-data'
            }
        })
    return data
})

export const getStructsTarif = createAsyncThunk('green_sante/entreprise/tarifstruct', async (collection_id) => {
    const { data } = await axios.get(`admin/companies/${collection_id}/tarifstruct`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCollegesTypes = createAsyncThunk('green_sante/entreprise/collegetypes', async () => {
    const { data } = await axios.get(`admin/company/collegestypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getRiskTypes = createAsyncThunk('green_sante/entreprise/risktypes', async () => {
    const { data } = await axios.get(`admin/risktypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const deleteCompanyContractFile = createAsyncThunk('green_sante/entreprise/contracts/delete', async ({ contract_company_file_id, file_id }) => {
    const { data } = await axios.delete(`admin/company/${contract_company_file_id}/contractsfiles/${file_id}/delete`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCCN = createAsyncThunk('green_sante/entreprise/ccn_idcc', async () => {
    const { data } = await axios.get(`admin/idcc_cnn`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postEntreprisesCreate = createAsyncThunk('green_sante/entreprises/create', async (body) => {
    const { data } = await axios.post(`/admin/company`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const getEntrepriseInfosBySiret = createAsyncThunk('green_sante/entreprise/get_entreprise_infos_by_siret', async (siret) => {
    const { data } = await axios.get(`admin/company/${siret}/info`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getPricingAges = createAsyncThunk('green_sante/entreprise/pricingAges', async () => {
    const { data } = await axios.get(`admin/pricingAges`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getDeparments = createAsyncThunk('green_sante/entreprise/departments', async () => {
    const { data } = await axios.get(`admin/departments`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getContributionsBase = createAsyncThunk('green_sante/entrperise/contributionsBase', async () => {
    const { data } = await axios.get(`admin/contributionsBase`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getPricingTypes = createAsyncThunk('green_sante/entreprises/pricing_types', async () => {
    const { data } = await axios.get(`admin/pricingTypes`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanyContributionsBase = createAsyncThunk('green_sante/entreprises/company_contributionsBase', async () => {
    const { data } = await axios.get(`admin/companyContributionsBase`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const getAllUsers = createAsyncThunk('green_sante/entreprise/users_all', async (companyId) => {
    const { data } = await axios.get(`/admin/employees?company_id=${companyId}`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCompanyInterlocutorCreate = createAsyncThunk('green_sante/entreprise/interlocutor/create', async (body) => {
    const { data } = await axios.post(`/admin/company/interlocutor`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const postCompanyRHCreate = createAsyncThunk('green_sante/entreprise/rh/create', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/company/rh`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchUpdateEmployeeInfos = createAsyncThunk('green_sante/entreprise/update_employee_infos', async (body) => {
    const { data } = await axios.patch(`/admin/employees`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const postValidateEmployee = createAsyncThunk('green_sante/entreprise/employees/validate_employee', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`admin/employee/validate`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getCompanyNote = createAsyncThunk('green_sante/entreprise/get_note', async (company_id) => {
    const { data } = await axios.get(`/admin/companies/${company_id}/note`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateCompanyNote = createAsyncThunk('green_sante/entreprise/note/create_note', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/companies/note`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const deleteCompanyRH = createAsyncThunk('green_sante/entreprise/rh/delete', async ({ companyId, userId }) => {
    const { data } = await axios.delete(`/admin/company/rh_access/${companyId}/${userId}/delete`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const deleteCompanyInterlocutor = createAsyncThunk('green_sante/entreprise/interlocutor/delete', async ({ companyId, contact_point_id }) => {
    const { data } = await axios.delete(`/admin/company/interlocutor/${companyId}/${contact_point_id}/delete`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const patchUpdateFilesEnable = createAsyncThunk('green_sante/entreprise/files/update_enable', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`/admin/updatefilesenable`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getContractTypes = createAsyncThunk('green_sante/entreprises/contracttype', async () => {
    const { data } = await axios.get(`admin/contracttypes `, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postRequestAffiliation = createAsyncThunk('green_sante/entreprise/employees/request_affiliation', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`/admin/sendAffiliationrequest`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchEditInterlocutor = createAsyncThunk('green_sante/entreprise/interlocutor/edit', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`/admin/company/interlocutor`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})