import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer } from "react-toastify";

import './scss/style.scss'

import { userIsNotAuthenticated } from './security/Auth'

import { API_GREENSANTE } from './store/config'

axios.defaults.baseURL = API_GREENSANTE

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./components/pages/login/Login'))

const TheMessagerieApp = React.lazy(() =>
  import('./components/apps/email/TheMessagerieApp'),
)

const TheEmailApp = React.lazy(() =>
  import('./components/apps/email/TheEmailApp'),
)

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              component={userIsNotAuthenticated(Login)}
            />

            <Route
              path="/messagerie"
              name="messagerie App"
              render={(props) => <TheMessagerieApp {...props} />}
            />
            <Route
              path="/email"
              name="email App"
              render={(props) => <TheEmailApp {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
        <ToastContainer />
      </HashRouter>
    )
  }
}

export default App
