import { createSlice } from '@reduxjs/toolkit'

import {
    getCompaniesExtra,
    getCompanyDashboardExtra,
    getCompanyContractsExtra,
    getCompanyEmployeesExtra,
    getCompanyEmployeeExtra,
    patchEditCompanyContractExtra,
    getCollectionsExtra,
    postCreateCompanyContractExtra,
    getContractFileTypesExtra,
    postFilesContractExtra,
    patchUpdateCompanyExtra,
    getCompanyDocumentsExtra,
    getDocumentsFileTypeExtra,
    postFileDocumentsExtra,
    getStructsTarifExtra,
    getCollegesTypesExtra,
    deleteCompanyContractFileExtra,
    getRiskTypesExtra,
    getCCNExtra,
    postEntreprisesCreateExtra,
    getEntrepriseInfosBySiretExtra,
    getPricingAgesExtra,
    getDeparmentsExtra,
    getContributionsBaseExtra,
    getPricingTypesExtra,
    getCompanyContributionsBaseExtra,
    getAllUsersExtra,
    postCompanyInterlocutorCreateExtra,
    postCompanyRHCreateExtra,
    patchUpdateEmployeeInfosExtra,
    postValidateEmployeeExtra,
    getCompanyNoteExtra,
    postCreateCompanyNoteExtra,
    deleteCompanyInterlocutorExtra,
    deleteCompanyRHExtra,
    patchUpdateFilesEnableExtra,
    getContractTypesExtra,
    postRequestAffiliationExtra,
    patchEditInterlocutorExtra
} from './entreprisesExtra'

const extraReducers = {
    ...getCompaniesExtra,
    ...getCompanyDashboardExtra,
    ...getCompanyContractsExtra,
    ...getCompanyEmployeesExtra,
    ...getCompanyEmployeeExtra,
    ...patchEditCompanyContractExtra,
    ...getCollectionsExtra,
    ...postCreateCompanyContractExtra,
    ...getContractFileTypesExtra,
    ...postFilesContractExtra,
    ...patchUpdateCompanyExtra,
    ...getCompanyDocumentsExtra,
    ...getDocumentsFileTypeExtra,
    ...postFileDocumentsExtra,
    ...getStructsTarifExtra,
    ...getCollegesTypesExtra,
    ...deleteCompanyContractFileExtra,
    ...getRiskTypesExtra,
    ...getCCNExtra,
    ...postEntreprisesCreateExtra,
    ...getEntrepriseInfosBySiretExtra,
    ...getPricingAgesExtra,
    ...getDeparmentsExtra,
    ...getContributionsBaseExtra,
    ...getPricingTypesExtra,
    ...getCompanyContributionsBaseExtra,
    ...getAllUsersExtra,
    ...postCompanyInterlocutorCreateExtra,
    ...postCompanyRHCreateExtra,
    ...patchUpdateEmployeeInfosExtra,
    ...postValidateEmployeeExtra,
    ...getCompanyNoteExtra,
    ...postCreateCompanyNoteExtra,
    ...deleteCompanyInterlocutorExtra,
    ...deleteCompanyRHExtra,
    ...patchUpdateFilesEnableExtra,
    ...getContractTypesExtra,
    ...postRequestAffiliationExtra,
    ...patchEditInterlocutorExtra
}

export const entrepriseSlice = createSlice({
    name: 'entreprises',
    initialState: {
        entreprises: [],
        entreprise: {},

        employees: [],
        employee: {},

        loading: false,
        siretLoading: false,

        contracts: {
            data: [],
            loading: false,
        },
        contract: {},
        contracttypes: [],

        documents: {
            data: [],
            loading: false,
            select: []
        },

        note: {
            html: '',
            loading: false,
        },

        collections: [],
        structstarif: [],
        collegestypes: [],
        risktypes: [],
        ccn: [],
        pricingAges: [],
        departments: [],
        contributionsBase: [],
        pricingtypes: [],
        companyContributionsBase: [],
        allusers: [],
        contractFileTypes: [],

        modalCreateContract: {
            isActive: false,
            step1: false,
            step2: false,
            loading: false
        },
        modalEdit: false,
        files: []
    },
    reducers: {
        setEntreprises: (state, { payload }) => {
            state.entreprises = payload
        },
        setEntreprise: (state, { payload }) => {
            state.entreprise = payload
        },
        setContracts: (state, { payload }) => {
            state.contracts.data = payload
        },
        setEmployee: (state, { payload }) => {
            state.employee = payload
        },
        setContract: (state, { payload }) => {
            state.contract = payload
        },
        setModalEdit: (state, { payload }) => {
            state.modalEdit = payload
        },
        setStructstarif: (state, { payload }) => {
            state.structstarif = payload
        },
        setModalCreateActive: (state) => {
            state.modalCreateContract.isActive = true
        },
        setModalCreateStep1: (state) => {
            state.modalCreateContract.step1 = true
            state.modalCreateContract.step2 = false
        },
        resetModalCreate: (state) => {
            state.modalCreateContract.step2 = false
            state.modalCreateContract.step1 = false
            state.modalCreateContract.isActive = false
        },
        setFiles: (state, { payload }) => {
            state.files = payload
        },
    },
    extraReducers: {
        ...extraReducers
    }
})

export const {
    setEntreprises,
    setEntreprise,
    setContracts,
    setEmployee,
    setContract,
    setModalCreateActive,
    setModalCreateStep1,
    setModalCreateStep2,
    resetModalCreate,
    setModalEdit,
    setStructstarif,
    setFiles
} = entrepriseSlice.actions

export default entrepriseSlice.reducer