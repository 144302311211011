import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_MESSAGE = 'FETCH_MESSAGE'
const FETCH_MESSAGE_PENDING = 'FETCH_MESSAGE_PENDING'
const FETCH_MESSAGE_REJECTED = 'FETCH_MESSAGE_REJECTED'
const FETCH_MESSAGE_FULFILLED = 'FETCH_MESSAGE_FULFILLED'

const FETCH_MESSAGE_USER = 'FETCH_MESSAGE_USER'
const FETCH_MESSAGE_USER_PENDING = 'FETCH_MESSAGE_USER_PENDING'
const FETCH_MESSAGE_USER_REJECTED = 'FETCH_MESSAGE_USER_REJECTED'
const FETCH_MESSAGE_USER_FULFILLED = 'FETCH_MESSAGE_USER_FULFILLED'

const POST_MESSAGE = 'POST_MESSAGE'
const POST_MESSAGE_PENDING = 'POST_MESSAGE_PENDING'
const POST_MESSAGE_REJECTED = 'POST_MESSAGE_REJECTED'
const POST_MESSAGE_FULFILLED = 'POST_MESSAGE_FULFILLED'

export const fetchMessage = (payload) => ({ type: FETCH_MESSAGE, payload })

export const fetchMessagesPending = () => ({
  type: FETCH_MESSAGE_PENDING,
})
export const fetchMessagesRejected = (payload) => ({
  type: FETCH_MESSAGE_REJECTED,
  payload,
})
export const fetchMessagesFulfilled = (payload) => ({
  type: FETCH_MESSAGE_FULFILLED,
  payload,
})

export const fetchMessageUser = (payload) => ({
  type: FETCH_MESSAGE_USER,
  payload,
})

export const fetchMessageUsersPending = () => ({
  type: FETCH_MESSAGE_USER_PENDING,
})
export const fetchMessageUsersRejected = (payload) => ({
  type: FETCH_MESSAGE_USER_REJECTED,
  payload,
})
export const fetchMessageUsersFulfilled = (payload) => ({
  type: FETCH_MESSAGE_USER_FULFILLED,
  payload,
})

export const PostMessage = (payload) => ({ type: POST_MESSAGE, payload })

export const PostMessagesPending = () => ({
  type: POST_MESSAGE_PENDING,
})
export const PostMessagesRejected = (payload) => ({
  type: POST_MESSAGE_REJECTED,
  payload,
})
export const PostMessagesFulfilled = (payload) => ({
  type: POST_MESSAGE_FULFILLED,
  payload,
})

// requetes HTTP AJAX

const fetchMessageEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_MESSAGE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/company/message/${action.payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchMessagesFulfilled(xhr.response.data.messages)),
          catchError((error) => of(fetchMessagesRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchMessagesPending()))

const fetchMessageUserEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_MESSAGE_USER),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/user/message`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchMessageUsersFulfilled(xhr.response.data.messages)),
          catchError((error) => of(fetchMessageUsersRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchMessageUsersPending()))

const postMessageEpic = (action$) =>
  action$
    .pipe(
      ofType(POST_MESSAGE),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/company/message/${action.payload.company_id}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            message: action.payload.message,
          },
        }).pipe(
          map((xhr) => PostMessagesFulfilled(xhr.response.message[0])),
          catchError((error) => of(PostMessagesRejected(error))),
        ),
      ),
    )
    .pipe(startWith(PostMessagesPending()))

export const MessagerieEpic = combineEpics(
  fetchMessageEpic,
  postMessageEpic,
  fetchMessageUserEpic,
)

const initialState = {
  messages: [],
  messagesUser: [],
  isFetching: false,
  error: null,
}

export const messageriReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGE_PENDING:
      return {
        ...state,
        isFetching: true,
        messages: initialState.messages,
      }
    case FETCH_MESSAGE_REJECTED:
      return {
        ...state,
        isFetching: false,
        messages: initialState.messages,
        error: action.payload,
      }
    case FETCH_MESSAGE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        messages: action.payload,
      }

    case FETCH_MESSAGE_USER_PENDING:
      return {
        ...state,
        isFetching: true,
        messagesUser: initialState.messagesUser,
      }
    case FETCH_MESSAGE_USER_REJECTED:
      return {
        ...state,
        isFetching: false,
        messagesUser: initialState.messagesUser,
        error: action.payload,
      }
    case FETCH_MESSAGE_USER_FULFILLED:
      return {
        ...state,
        isFetching: false,
        messagesUser: action.payload,
      }

    case POST_MESSAGE_PENDING:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case POST_MESSAGE_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case POST_MESSAGE_FULFILLED:
      let messages = [...state.messages]
      messages.push(action.payload)
      return {
        ...state,
        messages,
        isFetching: false,
        error: null,
      }
    default:
      return state
  }
}
