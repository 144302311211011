import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getRequests = createAsyncThunk('green_sante/requests', async () => {
    const { data } = await axios.get("/admin/requests", { headers: { Authorization: tokenHeader() } })
    return data
})

export const requestsSlice = createSlice({
    name: 'requests',
    initialState: {
        requests: [],
        loading: false
    },
    reducers: {
        setRequests: (state, { payload }) => {
            state.requests = payload
        },
    },
    extraReducers: {
        [getRequests.pending]: (state) => {
            state.loading = true
        },
        [getRequests.fulfilled]: (state, { payload }) => {
            state.loading = false
            payload.requests.forEach(request => {
                request.show = false
            });
            state.requests = payload?.requests
        },
        [getRequests.rejected]: (state) => {
            state.loading = false
        },
    }
})

export const { setRequests } = requestsSlice.actions

export default requestsSlice.reducer