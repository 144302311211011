import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { toastError, toastSuccess } from '../toast'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getContracts = createAsyncThunk('green_sante/contracts', async () => {
    const { data } = await axios.get("/admin/contracts", { headers: { Authorization: tokenHeader() } })
    return data
})

export const getCompanies = createAsyncThunk('green_sante/contracts/companies/get', async () => {
    const { data } = await axios.get("/admin/companies", { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateCompanyContract = createAsyncThunk('green_sante/contracts/create', async (body) => {
    const { data } = await axios.post(`/admin/company/contract`, body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const patchEditCompanyContract = createAsyncThunk('green_sante/contracts/edit', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`/admin/company/contracts`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getCollectionsInfos = createAsyncThunk('green_sante/contracts/collections_infos', async ({ collection_id, year }) => {
    const { data } = await axios.get(`/admin/collectioninfo?collection_id=${collection_id}&year=${year}`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const contractsSlice = createSlice({
    name: 'contracts',
    initialState: {
        contracts: [],
        contract: {},
        loading: false,

        companies: [],

        collection_infos: {},

        modalCreateContract: {
            isActive: false,
            step1: false,
            step2: false,
            loading: false
        },
        modalEdit: false,
        files: []
    },
    reducers: {
        setContract: (state, { payload }) => {
            state.contract = payload
        },
        setContracts: (state, { payload }) => {
            state.contracts = payload
        },
        setModalCreateActive: (state) => {
            state.modalCreateContract.isActive = true
        },
        setModalCreateStep1: (state) => {
            state.modalCreateContract.step1 = true
            state.modalCreateContract.step2 = false
        },
        resetModalCreate: (state) => {
            state.modalCreateContract.step2 = false
            state.modalCreateContract.step1 = false
            state.modalCreateContract.isActive = false
        },
        setFiles: (state, { payload }) => {
            state.files = payload
        },
        setModalEdit: (state, { payload }) => {
            state.modalEdit = payload
        },
    },
    extraReducers: {
        [getContracts.pending]: (state) => {
            state.loading = true
        },
        [getContracts.fulfilled]: (state, { payload }) => {
            state.loading = false
            payload.contracts.forEach(request => {
                request.show = false
            });
            state.contracts = payload?.contracts
        },
        [getContracts.rejected]: (state) => {
            state.loading = false
        },

        [getCompanies.pending]: (state) => {
        },
        [getCompanies.fulfilled]: (state, { payload }) => {
            payload.data.companies?.forEach(company => {
                company.label = company.company_name
                company.value = company.company_id
            });
            state.companies = payload?.data.companies
        },
        [getCompanies.rejected]: (state) => {
        },

        [postCreateCompanyContract.pending]: (state) => {
            state.modalCreateContract.loading = true
        },
        [postCreateCompanyContract.fulfilled]: (state, { payload }) => {
            state.modalCreateContract.loading = false
            state.modalCreateContract.step2 = true
            state.modalCreateContract.step1 = false
            state.contract = payload.contract
            toastSuccess(`Le contrat ${payload.contract.number} à bién été crée, vous pouvez maintenant upload des fichiers`);
        },
        [postCreateCompanyContract.rejected]: (state) => {
            state.modalCreateContract.loading = false
            toastError('Une erreur à eu lieu durant la création du contrat, veuillez recommencer')
        },

        [patchEditCompanyContract.pending]: (state) => {
        },
        [patchEditCompanyContract.fulfilled]: (state, { payload }) => {
            state.contract = {}
            toastSuccess(payload.message);
        },
        [patchEditCompanyContract.rejected]: (state, { payload }) => {
            state.contract = {}
            toastError(payload || "Erreur durant la modification du contrat");
        },

        [getCollectionsInfos.pending]: (state) => {
        },
        [getCollectionsInfos.fulfilled]: (state, { payload: { data } }) => {
            if (data.pricing_location !== false) {
                data.pricing_location?.forEach((p) => {
                    p.label = p.pricing_location_name
                    p.value = p.pricing_location_id
                })
            }
            if (data.pricing_age !== false) {
                data.pricing_age?.forEach((p) => {
                    p.label = p.pricing_age_name
                    p.value = p.pricing_age_id
                })
            }
            state.collection_infos = data
        },
        [getCollectionsInfos.rejected]: () => {
            toastError("Erreur durant la récupération des informations de la collection");
        },
    }
})

export const {
    setContract,
    setContracts,
    setModalCreateActive,
    setModalCreateStep1,
    resetModalCreate,
    setFiles,
    setModalEdit } = contractsSlice.actions

export default contractsSlice.reducer