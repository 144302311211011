import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const FETCH_INFO = 'FETCH_INFO'
const FETCH_INFO_PENDING = 'FETCH_INFO_PENDING'
const FETCH_INFO_REJECTED = 'FETCH_INFO_REJECTED'
const FETCH_INFO_FULFILLED = 'FETCH_INFO_FULFILLED'

const FETCH_INFO_CCN = 'FETCH_INFO_CCN'
const FETCH_INFO_CCN_PENDING = 'FETCH_INFO_CCN_PENDING'
const FETCH_INFO_CCN_REJECTED = 'FETCH_INFO_CCN_REJECTED'
const FETCH_INFO_CCN_FULFILLED = 'FETCH_INFO_CCN_FULFILLED'

const FETCH_CONTACT_TYPE = 'FETCH_CONTACT_TYPE'
const FETCH_CONTACT_TYPE_PENDING = 'FETCH_CONTACT_TYPE_PENDING'
const FETCH_CONTACT_TYPE_REJECTED = 'FETCH_CONTACT_TYPE_REJECTED'
const FETCH_CONTACT_TYPE_FULFILLED = 'FETCH_CONTACT_TYPE_FULFILLED'

const FETCH_MARITAL_STATUS = 'FETCH_MARITAL_STATUS'
const FETCH_MARITAL_STATUS_PENDING = 'FETCH_MARITAL_STATUS_PENDING'
const FETCH_MARITAL_STATUS_REJECTED = 'FETCH_MARITAL_STATUS_REJECTED'
const FETCH_MARITAL_STATUS_FULFILLED = 'FETCH_MARITAL_STATUS_FULFILLED'

// Action creators
export const fetchInfo = () => ({ type: FETCH_INFO })
export const fetchInfosPending = () => ({ type: FETCH_INFO_PENDING })
export const fetchInfosRejected = (payload) => ({
  type: FETCH_INFO_REJECTED,
  payload,
})
export const fetchInfosFulfilled = (payload) => ({
  type: FETCH_INFO_FULFILLED,
  payload,
})

// Action creators
export const fetchMaritalStatus = () => ({ type: FETCH_MARITAL_STATUS })
export const fetchMaritalStatusPending = () => ({
  type: FETCH_MARITAL_STATUS_PENDING,
})
export const fetchMaritalStatusRejected = (payload) => ({
  type: FETCH_MARITAL_STATUS_REJECTED,
  payload,
})
export const fetchMaritalStatusFulfilled = (payload) => ({
  type: FETCH_MARITAL_STATUS_FULFILLED,
  payload,
})

// Action creators
export const fetchInfoCcn = () => ({ type: FETCH_INFO_CCN })
export const fetchInfosCcnPending = () => ({ type: FETCH_INFO_CCN_PENDING })
export const fetchInfosCcnRejected = (payload) => ({
  type: FETCH_INFO_CCN_REJECTED,
  payload,
})
export const fetchInfosCcnFulfilled = (payload) => ({
  type: FETCH_INFO_CCN_FULFILLED,
  payload,
})

// Action creators
export const fetchContactType = () => ({ type: FETCH_CONTACT_TYPE })

export const fetchContactTypePending = () => ({
  type: FETCH_CONTACT_TYPE_PENDING,
})
export const fetchContactTypeRejected = (payload) => ({
  type: FETCH_CONTACT_TYPE_REJECTED,
  payload,
})
export const fetchContactTypeFulfilled = (payload) => ({
  type: FETCH_CONTACT_TYPE_FULFILLED,
  payload,
})

// requetes HTTP AJAX

const fetchInfoEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_INFO),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/osinfos`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) => fetchInfosFulfilled(xhr.response.data)),
          catchError((error) => of(fetchInfosRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchInfosPending()))

const fetchMaritalStatusEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_MARITAL_STATUS),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/maritalStatus`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchMaritalStatusFulfilled(xhr.response.data.maritalType),
          ),
          catchError((error) => of(fetchMaritalStatusRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchMaritalStatusPending()))

const fetchContactTypeEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_INFO),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/admin/contactType`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchContactTypeFulfilled(xhr.response.data.contactstype),
          ),
          catchError((error) => of(fetchContactTypeRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchContactTypePending()))

const fetchInfoCcnEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_INFO_CCN),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/tools/ccn`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).pipe(
          map((xhr) =>
            fetchInfosCcnFulfilled(xhr.response.data.allconventions),
          ),
          catchError((error) => of(fetchInfosCcnRejected(error))),
        ),
      ),
    )
    .pipe(startWith(fetchInfosCcnPending()))

export const InfoEpic = combineEpics(
  fetchInfoEpic,
  fetchInfoCcnEpic,
  fetchContactTypeEpic,
  fetchMaritalStatusEpic,
)

const initialState = {
  info: {},
  ccn: [],
  contactsType: [],
  materialsStatus: [],
  isFetching: false,
  error: null,
}

export const InfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INFO_PENDING:
      return { ...state, isFetching: true, info: initialState.info }
    case FETCH_INFO_REJECTED:
      return {
        ...state,
        isFetching: false,
        info: initialState.info,
        error: action.payload,
      }
    case FETCH_INFO_FULFILLED:
      return { ...state, isFetching: false, info: action.payload }

    case FETCH_INFO_CCN_PENDING:
      return { ...state, isFetching: true, ccn: initialState.ccn }
    case FETCH_INFO_CCN_REJECTED:
      return {
        ...state,
        isFetching: false,
        ccn: initialState.ccn,
        error: action.payload,
      }
    case FETCH_INFO_CCN_FULFILLED:
      return { ...state, isFetching: false, ccn: action.payload }

    case FETCH_CONTACT_TYPE_PENDING:
      return {
        ...state,
        isFetching: true,
        contactsType: initialState.contactsType,
      }
    case FETCH_CONTACT_TYPE_REJECTED:
      return {
        ...state,
        isFetching: false,
        contactsType: initialState.contactsType,
        error: action.payload,
      }
    case FETCH_CONTACT_TYPE_FULFILLED:
      return { ...state, isFetching: false, contactsType: action.payload }

    case FETCH_MARITAL_STATUS_PENDING:
      return {
        ...state,
        isFetching: true,
        materialsStatus: initialState.materialsStatus,
      }
    case FETCH_MARITAL_STATUS_REJECTED:
      return {
        ...state,
        isFetching: false,
        materialsStatus: initialState.materialsStatus,
        error: action.payload,
      }
    case FETCH_MARITAL_STATUS_FULFILLED:
      return { ...state, isFetching: false, materialsStatus: action.payload }
    default:
      return state
  }
}
