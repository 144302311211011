import { ajax } from 'rxjs/ajax'
import { mergeMap, map, catchError, startWith } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { combineEpics } from 'redux-observable'

import { API_GREENSANTE } from '../config'

// ACTION TYPES
const AUTHENTICATION = 'AUTHENTICATION'
const AUTHENTICATION_PENDING = 'AUTHENTICATION_PENDING'
const AUTHENTICATION_REJECTED = 'AUTHENTICATION_REJECTED'
const AUTHENTICATION_FULFILLED = 'AUTHENTICATION_FULFILLED'
const LOGOUT = 'LOGOUT'

// Action creators
export const authenticationn = (user) => ({ type: AUTHENTICATION, user })
export const authenticationnFulfilled = (payload) => ({
  type: AUTHENTICATION_FULFILLED,
  payload,
})
export const authenticationnRejected = (payload) => ({
  type: AUTHENTICATION_REJECTED,
  payload,
})
export const authenticationnPending = () => ({ type: AUTHENTICATION_PENDING })
export const logout = () => ({ type: LOGOUT })

// requetes HTTP AJAX
const authenticationnEpic = (action$) =>
  action$
    .pipe(
      ofType(AUTHENTICATION),
      mergeMap((action) =>
        ajax({
          url: `${API_GREENSANTE}/login`,
          method: 'POST',
          responseType: 'json',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body:
            'email=' +
            encodeURI(action.user.username) +
            '&password=' +
            encodeURI(action.user.password),
        }).pipe(
          map((xhr) => authenticationnFulfilled(xhr.response.data)),
          catchError((error) =>
            of(authenticationnRejected(error.response.message)),
          ),
        ),
      ),
    )
    .pipe(startWith(authenticationnPending()))

export const accountEpic = combineEpics(authenticationnEpic)

const initialState = {
  authentication: {},
  isFetching: false,
  error: null,
}

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_PENDING:
      return {
        ...state,
        isFetching: true,
        authentication: initialState.authentication,
      }
    case AUTHENTICATION_REJECTED:
      return {
        ...state,
        isFetching: false,
        authentication: initialState.authentication,
        error: action.payload,
      }
    case AUTHENTICATION_FULFILLED:
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('user_id', action.payload.user_id)
      return {
        ...state,
        isFetching: false,
        authentication: action.payload,
        error: null,
      }
    case LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        isFetching: true,
        authentication: initialState.authentication,
        error: null,
      }
    default:
      return state
  }
}
