
import { toastSuccess, toastError } from '../toast'

import {
    getEmployees,
    getCollegesTypes,
    getCompanies,
    getCompanyContracts,
    postCreateEmployee,
    getCompanyEmployee,
    getEmployeeContracts,
    getEmployeeDocuments,
    getDocumentsFileType,
    postCreateEmployeeContract,
    getContractFileTypes,
    patchUpdateEmployeeInfos,
    patchUpdateEmployeeContract,
    postFileDocumentsEmployee,
    getEmployeeNote,
    postCreateEmployeeNote,
    postCreateEmployeeRecipient,
    getRecipientTypes,
    getContractTypes,
    patchUpdateEmployeeRecipient
} from './employeesChunk'

export const getCollegesTypesExtra = {
    [getCollegesTypes.pending]: (state) => {
    },
    [getCollegesTypes.fulfilled]: (state, { payload: { data } }) => {
        state.collegestypes = data
    },
    [getCollegesTypes.rejected]: () => {
        toastError("Erreur durant la récupération des colleges types");
    },
}

export const getEmployeesExtra = {
    [getEmployees.pending]: (state) => {
        state.loading = true
    },
    [getEmployees.fulfilled]: (state, { payload }) => {
        state.loading = false
        payload.employees.forEach(employee => {
            employee.show = false
        });
        state.employees = payload?.employees
    },
    [getEmployees.rejected]: (state) => {
        state.loading = false
    },
}

export const getCompaniesExtra = {
    [getCompanies.pending]: (state) => {
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
        payload.data.companies?.forEach(company => {
            company.label = company.company_name
            company.value = company.company_id
        });
        state.companies = payload?.data.companies
    },
    [getCompanies.rejected]: (state) => {
    },
}

export const getCompanyContractsExtra = {
    [getCompanyContracts.pending]: (state) => {
        state.contracts_company_loading = true
    },
    [getCompanyContracts.fulfilled]: (state, { payload }) => {
        state.contracts_company_loading = false
        state.contracts_company = payload.contracts
        state.contracts_company.forEach(contract => {
            contract.label = contract.number
            contract.value = contract.contract_company_id
        });
    },
    [getCompanyContracts.rejected]: (state) => {
        state.contracts_company_loading = false
        toastError(`Erreur durant la récupération des contrats de l'entreprise`);
    },
}

export const postCreateEmployeeExtra = {

    [postCreateEmployee.pending]: (state) => {
    },
    [postCreateEmployee.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postCreateEmployee.rejected]: (state) => {
        toastError(`Erreur durant la création du salarié`);
    },
}

export const getCompanyEmployeeExtra = {
    [getCompanyEmployee.pending]: (state) => {
        state.loading = true
    },
    [getCompanyEmployee.fulfilled]: (state, { payload }) => {
        state.loading = false
        payload.employee.recipients.forEach(recipient => {
            recipient.show = false
        });
        state.employee = { ...state.employee, ...payload.employee }
    },
    [getCompanyEmployee.rejected]: (state) => {
        state.loading = false
    },
}

export const getEmployeeContractsExtra = {
    [getEmployeeContracts.pending]: (state) => {
        state.contracts.loading = true
    },
    [getEmployeeContracts.fulfilled]: (state, { payload }) => {
        state.contracts.loading = false
        payload.data.forEach(c => {
            c.show = false
        });
        state.contracts.data = payload.data
    },
    [getEmployeeContracts.rejected]: (state) => {
        state.contracts.loading = false
        toastError("Erreur durant la récupération des contracts du salarié")
    },
}

export const getEmployeeDocumentsExtra = {
    [getEmployeeDocuments.pending]: (state) => {
        state.documents.loading = true
    },
    [getEmployeeDocuments.fulfilled]: (state, { payload }) => {
        state.documents.loading = false
        state.documents.data = payload.data
    },
    [getEmployeeDocuments.rejected]: (state) => {
        state.documents.loading = false
        toastError("Erreur durant la récupération des documents du salarié")
    },
}

export const getDocumentsFileTypeExtra = {
    [getDocumentsFileType.pending]: (state) => {
    },
    [getDocumentsFileType.fulfilled]: (state, { payload: { data } }) => {
        state.documents.select = data.map((el) => {
            return {
                value: el.filetype_id,
                label: el.name,
            };
        })
    },
    [getDocumentsFileType.rejected]: () => {
        toastError("Erreur durant la récupération des types de fichiers");
    },
}

export const postCreateEmployeeContractExtra = {
    [postCreateEmployeeContract.pending]: (state) => {
        state.modalCreateContract.loading = true
    },
    [postCreateEmployeeContract.fulfilled]: (state, { payload }) => {
        state.modalCreateContract.loading = false
        // state.modalCreateContract.step2 = true
        state.modalCreateContract.step1 = false
        state.contract = payload.contract_user
        toastSuccess(payload.message);
    },
    [postCreateEmployeeContract.rejected]: (state, { payload }) => {
        state.modalCreateContract.loading = false
        toastError(payload)
    },
}

export const getContractFileTypesExtra = {
    [getContractFileTypes.pending]: (state) => {
    },
    [getContractFileTypes.fulfilled]: (state, { payload }) => {
        state.contractFileTypes = payload?.contractfiles || []
    },
    [getContractFileTypes.rejected]: (state) => {
    },
}

export const patchUpdateEmployeeInfosExtra = {
    [patchUpdateEmployeeInfos.pending]: (state) => {
    },
    [patchUpdateEmployeeInfos.fulfilled]: (state, { payload }) => {
        toastSuccess('Le salarié à bien été mis à jour')
    },
    [patchUpdateEmployeeInfos.rejected]: (state) => {
        toastError('Erreur durant la mise à jour du salarié')
    },
}

export const patchUpdateEmployeeContractExtra = {
    [patchUpdateEmployeeContract.pending]: (state) => {
    },
    [patchUpdateEmployeeContract.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [patchUpdateEmployeeContract.rejected]: (state, { payload }) => {
        toastError(payload)
    },
}

export const postFileDocumentsEmployeeExtra = {
    [postFileDocumentsEmployee.pending]: (state) => {
    },
    [postFileDocumentsEmployee.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postFileDocumentsEmployee.rejected]: () => {
        toastError("L'upload du document à échoué")
    },
}

export const getEmployeeNoteExtra = {
    [getEmployeeNote.pending]: (state) => {
        state.note.loading = true
    },
    [getEmployeeNote.fulfilled]: (state, { payload }) => {
        state.note.loading = false
        state.note.html = payload.note.note
    },
    [getEmployeeNote.rejected]: (state, { payload }) => {
        state.note.loading = false
        toastError(payload);
    },
}

export const postCreateEmployeeNoteExtra = {
    [postCreateEmployeeNote.pending]: () => {
    },
    [postCreateEmployeeNote.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postCreateEmployeeNote.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const postCreateEmployeeRecipientExtra = {
    [postCreateEmployeeRecipient.pending]: () => {
    },
    [postCreateEmployeeRecipient.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [postCreateEmployeeRecipient.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}

export const getRecipientTypesExtra = {
    [getRecipientTypes.pending]: () => {
    },
    [getRecipientTypes.fulfilled]: (state, { payload: { data } }) => {
        state.recipientTypes = data.map((el) => {
            return {
                value: el.recipienttype_id,
                label: el.name,
            };
        })
    },
    [getRecipientTypes.rejected]: (state, { payload }) => {
        toastError("Erreur durant la récupération des types de bénéficiaires");
    },
}

export const getContractTypesExtra = {
    [getContractTypes.pending]: (state) => {
    },
    [getContractTypes.fulfilled]: (state, { payload: { data } }) => {
        state.contracttypes = data
    },
    [getContractTypes.rejected]: () => {
        toastError("Erreur durant la récupération des types de contrats");
    },
}

export const patchUpdateEmployeeRecipientExtra = {
    [patchUpdateEmployeeRecipient.pending]: () => {
    },
    [patchUpdateEmployeeRecipient.fulfilled]: (state, { payload: { message } }) => {
        toastSuccess(message)
    },
    [patchUpdateEmployeeRecipient.rejected]: (state, { payload }) => {
        toastError(payload);
    },
}