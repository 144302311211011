import { createSlice } from '@reduxjs/toolkit'

import {
    getCompaniesExtra,
    getEmployeesExtra,
    getCompanyContractsExtra,
    postCreateEmployeeExtra,
    getCollegesTypesExtra,
    getCompanyEmployeeExtra,
    getEmployeeContractsExtra,
    getEmployeeDocumentsExtra,
    getDocumentsFileTypeExtra,
    postCreateEmployeeContractExtra,
    getContractFileTypesExtra,
    patchUpdateEmployeeInfosExtra,
    patchUpdateEmployeeContractExtra,
    postFileDocumentsEmployeeExtra,
    getEmployeeNoteExtra,
    postCreateEmployeeNoteExtra,
    postCreateEmployeeRecipientExtra,
    getRecipientTypesExtra,
    getContractTypesExtra,
    patchUpdateEmployeeRecipientExtra
} from './employeesExtra'

const extraReducers = {
    ...getCompaniesExtra,
    ...getEmployeesExtra,
    ...getCompanyContractsExtra,
    ...postCreateEmployeeExtra,
    ...getCollegesTypesExtra,
    ...getCompanyEmployeeExtra,
    ...getEmployeeContractsExtra,
    ...getEmployeeDocumentsExtra,
    ...getDocumentsFileTypeExtra,
    ...postCreateEmployeeContractExtra,
    ...getContractFileTypesExtra,
    ...patchUpdateEmployeeInfosExtra,
    ...patchUpdateEmployeeContractExtra,
    ...postFileDocumentsEmployeeExtra,
    ...getEmployeeNoteExtra,
    ...postCreateEmployeeNoteExtra,
    ...postCreateEmployeeRecipientExtra,
    ...getRecipientTypesExtra,
    ...getContractTypesExtra,
    ...patchUpdateEmployeeRecipientExtra
}

export const employeesSlice = createSlice({
    name: 'employees',
    initialState: {
        employees: [],
        companies: [],

        recipientTypes: [],

        files: [],

        contracts_company: [],
        contracts_company_loading: false,

        employee: {},
        collegestypes: [],

        loading: false,
        college: null,

        note: {
            html: '',
            loading: false,
        },

        contracts: {
            data: [],
            loading: false
        },
        contract: {},
        contractFileTypes: [],
        contracttypes: [],

        recipient: {},

        documents: {
            data: [],
            select: [],
            loading: false
        },

        modalCreateContract: {
            isActive: false,
            step1: false,
            step2: false,
            loading: false
        },
    },
    reducers: {
        setEmployee: (state, { payload }) => {
            state.employee = payload
        },
        setContract: (state, { payload }) => {
            state.contract = payload
        },
        setContracts: (state, { payload }) => {
            state.contracts.data = payload
        },
        setModalCreateActive: (state) => {
            state.modalCreateContract.isActive = true
        },
        setModalCreateStep1: (state) => {
            state.modalCreateContract.step1 = true
            state.modalCreateContract.step2 = false
        },
        resetModalCreate: (state) => {
            state.modalCreateContract.step2 = false
            state.modalCreateContract.step1 = false
            state.modalCreateContract.isActive = false
        },
        setFiles: (state, { payload }) => {
            state.files = payload
        },
        setRecipient: (state, { payload }) => {
            state.recipient = payload
        },
        resetNote: (state) => {
            state.note = {
                html: '',
                loading: false,
            }
        },
    },
    extraReducers: {
        ...extraReducers
    }
})

export const {
    setEmployee, setContract,
    setContracts, setModalCreateActive, setModalCreateStep1,
    resetModalCreate, setFiles, setRecipient, resetNote
} = employeesSlice.actions

export default employeesSlice.reducer