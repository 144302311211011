import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { toastSuccess, toastError, toastSuccessFast, toastErrorFast } from '../toast'

const tokenHeader = () => `Bearer ${localStorage.getItem('token')}`

export const getGuarantees = createAsyncThunk('green_sante/guarantees', async () => {
    const { data } = await axios.get("admin/guaranteespack", { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateGuarantee = createAsyncThunk('green_sante/guarantees/create', async (formdata) => {
    const { data } = await axios.post("admin/guaranteespack ", formdata,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'multipart/form-data'
            }
        })
    return data
})

export const postUploadExcelGurantee = createAsyncThunk('green_sante/guarantees/excel_upload', async (formdata) => {
    const { data } = await axios.post("admin/guarantees/uploadfileexcel", formdata,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'multipart/form-data'
            }
        })
    return data
})

export const postUpdateGuarantee = createAsyncThunk('green_sante/guarantees/update', async (body) => {
    const { data } = await axios.patch("admin/guaranteespack ", body,
        {
            headers: {
                Authorization: tokenHeader(),
                'Content-Type': 'application/json'
            }
        })
    return data
})

export const patchUpdateGuaranteesEnable = createAsyncThunk('green_sante/guarantees/update_enable', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/guaranteesPack/enable`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getGuaranteeData = createAsyncThunk('green_sante/guarantee/data', async (guarantee_pack_id) => {
    const { data } = await axios.get(`admin/guaranteepackvalues?guarantee_pack_id=${guarantee_pack_id}`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postCreateCategory = createAsyncThunk('green_sante/guarantee/category/create', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`admin/guaranteecategory`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const postCreateGuaranteeInTab = createAsyncThunk('green_sante/guarantee/guarantee/create', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`admin/guarantee`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchGuaranteeInTab = createAsyncThunk('green_sante/guarantee/update', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/guaranteecategory`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const postCreateGuaranteeValuesInTab = createAsyncThunk('green_sante/guarantee/guarantee/create_values', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`admin/guaranteepackvalue`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchUpdateGuaranteeValuesInTab = createAsyncThunk('green_sante/guarantee/guarantee/create_values', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/guaranteepackvalue`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchUpdateGuaranteeInTab = createAsyncThunk('green_sante/guarantee/guarantee/update', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/guarantee`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const patchLinkGuaranteeToCategory = createAsyncThunk('green_sante/guarantee/link_to_category', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.patch(`admin/linkguaranteetocategory`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const deleteGuaranteePackValue = createAsyncThunk('green_sante/guarantee/delete_pack_values', async (guarantee_pack_value_id, { rejectWithValue }) => {
    try {
        const { data } = await axios.delete(`admin/guaranteepackvalue/${guarantee_pack_value_id}`,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const deleteGuaranteeInTab = createAsyncThunk('green_sante/guarantee/delete_guarantee_in_tab', async (guarantee_id, { rejectWithValue }) => {
    try {
        const { data } = await axios.delete(`admin/guarantee/${guarantee_id}`,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const getGuaranteeActivityValue = createAsyncThunk('green_sante/guarantee/activityvalue', async (guarantee_pack_id) => {
    const { data } = await axios.get(`admin/guarantepackactivityvalue/${guarantee_pack_id}`, { headers: { Authorization: tokenHeader() } })
    return data
})

export const postPercentageActivityValue = createAsyncThunk('green_sante/guarantee/guarantee/value_activity', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`admin/guarantepackactivityvalue`, body,
            {
                headers: {
                    Authorization: tokenHeader(),
                    'Content-Type': 'application/json'
                }
            })
        return data
    } catch (err) {
        let error = err
        return rejectWithValue(error.response.data.error)
    }
})

export const guaranteesSlice = createSlice({
    name: 'guarantees',
    initialState: {
        guarantees: [],
        guarantee: {},
        loading: false,

        activities: [],
        activitiesLoading: false,
        activitiesValuesLoading: false,
    },
    reducers: {
        setGuarantee: (state, { payload }) => {
            state.guarantee = payload
        },
        setGuarantees: (state, { payload }) => {
            state.guarantees = payload
        },
        setActivities: (state, { payload }) => {
            state.activities = payload
        },
        setActivitiesInput: (state, { payload }) => {
            const { id,
                idxActivity,
                idxCategory,
                idxGuarantee,
                idxValue,
                value } = payload
            state.activities[idxActivity].categories[idxCategory].guaranties[idxGuarantee].values[idxValue][id] = value
        },
        setActivitiesGuaranteeInput: (state, { payload }) => {
            const { id,
                idxActivity,
                idxCategory,
                idxGuarantee,
                value } = payload
            state.activities[idxActivity].categories[idxCategory].guaranties[idxGuarantee][id] = value
        },
    },
    extraReducers: {
        [getGuarantees.pending]: (state) => {
            state.loading = true
        },
        [getGuarantees.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false
            data.forEach(guarantee => {
                guarantee.show = false
            });
            state.guarantees = data
        },
        [getGuarantees.rejected]: (state) => {
            state.loading = false
        },

        [postCreateGuarantee.pending]: (state) => {
        },
        [postCreateGuarantee.fulfilled]: (state, { payload }) => {
            toastSuccess(payload?.message || "Le pack à bien été créé")
        },
        [postCreateGuarantee.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la création du pack de garantie")
        },

        [postUpdateGuarantee.pending]: (state) => {
        },
        [postUpdateGuarantee.fulfilled]: (state, { payload }) => {
            toastSuccess(payload?.message || "Le pack à bien été mis à jour")
        },
        [postUpdateGuarantee.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la modification du pack de garantie")
        },

        [patchUpdateGuaranteesEnable.pending]: (state) => {
        },
        [patchUpdateGuaranteesEnable.fulfilled]: (state, { payload }) => {
            toastSuccess(payload?.message || "La visibilitée du pack à bien été mis à jour")
        },
        [patchUpdateGuaranteesEnable.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la modification du pack de garantie")
        },

        [getGuaranteeData.pending]: (state) => {
            state.activitiesLoading = true
        },
        [getGuaranteeData.fulfilled]: (state, { payload: { data: { activities } } }) => {
            activities.forEach((activity) => activity.value = "")
            state.activities = activities
            state.activitiesLoading = false
        },
        [getGuaranteeData.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la récupération du pack de garantie")
        },

        [postCreateCategory.pending]: (state) => {
        },
        [postCreateCategory.fulfilled]: (state, { payload }) => {
            toastSuccess(payload.message)
        },
        [postCreateCategory.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la création de la catégorie")
        },

        [postCreateGuaranteeInTab.pending]: (state) => {
        },
        [postCreateGuaranteeInTab.fulfilled]: (state, { payload }) => {
            toastSuccess(payload.message)
        },
        [postCreateGuaranteeInTab.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la création de la garantie")
        },

        [patchGuaranteeInTab.pending]: (state) => {
        },
        [patchGuaranteeInTab.fulfilled]: (state, { payload }) => {
            toastSuccess(payload.message)
        },
        [patchGuaranteeInTab.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la mise à jour de la catégorie")
        },

        [postCreateGuaranteeValuesInTab.pending]: (state) => {
        },
        [postCreateGuaranteeValuesInTab.fulfilled]: (state, { payload }) => {
            toastSuccess(payload.message)
        },
        [postCreateGuaranteeValuesInTab.rejected]: (state) => {
            toastError("Une erreur à eu lieu durant la création des valeurs de la garantie")
        },

        [patchUpdateGuaranteeValuesInTab.pending]: (state) => {
        },
        [patchUpdateGuaranteeValuesInTab.fulfilled]: (state, { payload }) => {
            toastSuccessFast(payload.message)
        },
        [patchUpdateGuaranteeValuesInTab.rejected]: (state) => {
            toastErrorFast("Une erreur à eu lieu durant la mise à jour des valeurs de la garantie")
        },

        [patchUpdateGuaranteeInTab.pending]: (state) => {
        },
        [patchUpdateGuaranteeInTab.fulfilled]: (state, { payload }) => {
            toastSuccessFast(payload.message)
        },
        [patchUpdateGuaranteeInTab.rejected]: (state) => {
            toastErrorFast("Une erreur à eu lieu durant la mise à jour de la description")
        },

        [patchLinkGuaranteeToCategory.pending]: (state) => {
        },
        [patchLinkGuaranteeToCategory.fulfilled]: (state, { payload }) => {
            toastSuccessFast("Liaison de la garantie à la catégorie réussi")
        },
        [patchLinkGuaranteeToCategory.rejected]: (state) => {
            toastErrorFast("Erreur durant la liaison de la garantie à la catégorie")
        },

        [deleteGuaranteePackValue.pending]: (state) => {
        },
        [deleteGuaranteePackValue.fulfilled]: (state, { payload }) => {
            toastSuccessFast(payload.message)
        },
        [deleteGuaranteePackValue.rejected]: (state) => {
            toastErrorFast("Erreur durant la supréssion de la valeur")
        },

        [deleteGuaranteeInTab.pending]: (state) => {
        },
        [deleteGuaranteeInTab.fulfilled]: (state, { payload }) => {
            toastSuccessFast(payload.message)
        },
        [deleteGuaranteeInTab.rejected]: (state) => {
            toastErrorFast("Erreur durant la supréssion de la valeur")
        },

        [getGuaranteeActivityValue.pending]: (state) => {
            state.activitiesValuesLoading = true
        },
        [getGuaranteeActivityValue.fulfilled]: (state, { payload: { data } }) => {
            data?.forEach((d) => {
                state.activities?.forEach((activity) => {
                    if (activity?.id === d?.guarantee_activity_id) {
                        activity.value = d?.value
                        activity.value_noss = d?.value_noss
                    }
                })
            })
            state.activitiesValuesLoading = false
        },
        [getGuaranteeActivityValue.rejected]: (state) => {
            toastErrorFast("Erreur durant la récupéraiton des valeurs des activités")
            state.activitiesValuesLoading = false
        },

        [postPercentageActivityValue.pending]: (state) => {
        },
        [postPercentageActivityValue.fulfilled]: (state, { payload }) => {
            toastSuccessFast(payload.message)
        },
        [postPercentageActivityValue.rejected]: (state) => {
            toastErrorFast("Erreur durant la modification du pourcentage")
        },
    }
})

export const { setGuarantee, setGuarantees, setActivities, setActivitiesInput, setActivitiesGuaranteeInput } = guaranteesSlice.actions

export default guaranteesSlice.reducer